$headings-font-family-bold: "IDX Sans Bold";
$headings-font-weight: var(--font-weight-semi-bold);
$tooltip-bg: rgba($primary, 0.2);
$input-border: $border-width solid $border-color;
$date-range-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
//package color
$package-essential-color: $blue;
$package-web-color: $indigo;
$package-standard-color: $purple;
$package-essentialPlusWP-color: $pink;
$package-premium-color: $red;
$package-essentialPlus-color: $orange;
$package-elite-color: $brown;
//********custom css********
#root {
  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
}
// header
.logo-company {
  height: 1.75rem;
}
.custom-tooltip span {
  display: block;
}
.navbar {
  position: fixed;
  width: 100%;
  padding: var(--base-spacing);
  top: 0;
  z-index: 999;
  @include media-breakpoint-down(md) {
    z-index: 999999;
  }
  border-bottom: 1px solid $outline-color;
  background-color: $nav-bg;
  .btn-toolbar {
    .btn {
      min-width: 10.5rem;
      text-align: left;
      background-color: $nav-bg;
    }
    .dropdown-menu {
      left: auto !important;
      right: 0 !important;
      border: $outline-color;
      min-width: 10.5rem;
      max-height: 15rem;
      overflow-y: auto;
      background-color: $nav-bg;
      .dropdown-item {
        padding-left: calc(2 * var(--base-spacing));
      }
    }
    &.profile-dropdown,
    .slider-dropdown {
      .dropdown-item {
        padding-left: calc(1 * var(--base-spacing));
      }
    }
  }
  @include media-breakpoint-down(md) {
    margin-left: 0;
    box-shadow: 0px 2px 4px 0px rgba($id-black, 0.23);
  }
  // mobile nav
  &.navbar-theme-primary:not(.headroom) {
    background-color: $nav-bg;
  }
  .navbar-toggler {
    margin-right: calc(-1 * var(--base-font-size));
    font-size: calc(1.5 * var(--base-font-size));
    background-color: transparent;
    border: transparent;
    color: $interactive-color;
    box-shadow: none;
    outline: none;
  }
}
.navbar-light,
.navbar-dark {
  .navbar-toggler-icon {
    background-image: none;
  }
}
// main content
.content {
  padding-top: calc(6 * var(--base-spacing));
  @include media-breakpoint-down(md) {
    padding-top: calc(8 * var(--base-spacing));
  }
  .web-analytics-date,
  .web-comp-date {
    @include media-breakpoint-down(lg) {
      display: inline-block;
      width: 100%;
      margin-top: var(--base-spacing);
      padding-left: var(--base-spacing);
    }
  }
}
.page-search-box {
  .table-search-box {
    padding-right: 0;
  }
}
.manage-users-page {
  &.page-search-box {
    position: absolute;
    right: calc(2 * var(--base-spacing));
    top: calc(2 * var(--base-spacing));
    @include media-breakpoint-down(md) {
      position: relative;
      margin-bottom: calc(2 * var(--base-spacing));
    }
  }
}
.my-document-listing {
  .card-body {
    min-height: 20rem;
    .card-no-data {
      top: 65%;
    }
  }
  .mydocument-actions {
    padding-left: calc(1 * var(--base-spacing)) !important;
  }
  .thead-light {
    th {
      &:last-child {
        min-width: 6rem;
        padding-left: calc(1 * var(--base-spacing)) !important;
      }
    }
  }
}
// sidebar
.sidebar {
  padding-top: calc(5.3125 * var(--base-spacing));
  @include media-breakpoint-down(md) {
    z-index: 999999 !important;
  }
  .nav {
    display: inline-block;
    width: 100%;
  }
  .sidebar-inner {
    padding-left: calc(0.75 * var(--base-spacing));
    padding-right: calc(0.75 * var(--base-spacing));
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
      .nav {
        padding-left: var(--base-spacing);
        padding-right: var(--base-spacing);
      }
      .user-card {
        padding: calc(1.125 * var(--base-spacing)) var(--base-spacing);
        box-shadow: 0px 2px 4px 0px rgba($id-black, 0.23);
      }
    }
    .nav-item {
      margin-bottom: 0;
      padding-top: 0;
      border: none;
      .nav-link {
        span {
          vertical-align: middle;
        }
      }
      .nav-link {
        padding: $sidenav-item-spacing;
        .sidebar-icon {
          margin-right: calc(0.25 * var(--base-spacing));
        }
       
        &.disabled {
          opacity: 0.5;
          pointer-events: visible;
          cursor: pointer;
        }
      }
      &.active {
        .nav-link {
          background-color: rgba($primary, 1);
          color: $id-white;
        }
      }
    }
    .header-company-name {
      float: left;
      padding-left: calc(2.25 * var(--base-spacing));
    }
    // >.nav {
    //     >.nav-item {
    //         &:last-child {
    //             margin-top: calc(.5 * var(--base-spacing));
    //             display: none;
    //             @include media-breakpoint-down(md) {
    //                 display: block;
    //             }
    //         }
    //     }
    // }
  }
  .profile-btn {
    padding: 0;
    float: left;
    font-size: calc(1.25 * var(--base-font-size));
    background-color: transparent;
    border: none;
    box-shadow: none;
    img {
      fill: $interactive-color;
    }
  }
  @include media-breakpoint-down(md) {
    padding-top: calc(0 * var(--base-spacing));
    z-index: 101;
  }
  .profile-name {
    float: left;
    font-size: calc(1.025 * var(--base-font-size));
  }
  background: $nav-bg;
  .collapse-close {
    margin-top: calc(-1.25 * var(--base-spacing));
    margin-left: calc(-0.625 * var(--base-spacing));
    margin-right: calc(-0.75 * var(--base-spacing));
    font-size: calc(1.3 * var(--base-font-size));
    outline: none;
    &.nav-link {
      color: $interactive-color;
    }
  }
  .simplebar-content-wrapper {
    height: 100% !important;
    .sidebar-inner {
      overflow-y: visible;
    }
  }
  .nav-item {
    .nav-link {
      padding: $sidenav-item-spacing;
      min-height: $sidenav-item-height;
      height: auto;
      border-radius: 0;
      font-size: var(--body-font-size);
      border-radius: 8px;
      // &:hover {
      //   background-color: transparent;
      // }
      &:focus {
        outline: none;
        box-shadow: none;
      }
      // &:after{
      //     margin-right: calc(.8125 * var(--base-font-size));
      // }
      span {
        display: inline-block;
        vertical-align: top;
      }
      &>span {
        width: 100%;
      }
      .sidebar-text {
        width: calc(100% - 30px);
        word-break: break-word;
        white-space: normal;
      }
    }
    .accordion-item {
      .multi-level {
        .nav-item {
          .nav-link {
            padding: $sidenav-subitem-spacing;
            margin-left: calc(-0.75 * var(--base-spacing));
            margin-right: calc(-0.75 * var(--base-spacing));
            color: $nav-link-color;
            
            .sidebar-icon {
              margin-right: calc(0.25 * var(--base-spacing));
            }
            &:hover {
              background-color: $id-bg-grey-c;
              color: $nav-link-color;
            }
          }
          &.active {
            .nav-link {
              background-color: rgba($primary, 1);
              color: $nav-link-hover-color;
            }
          }
        }
      }
    }
  }
  .sidebar-inner {
    .accordion {
      &.nav-item {
        .accordion-item {
          margin-bottom: 0;
          box-shadow: none;
          .nav-link {
            background-color: transparent;
            &.collapsed {
              &:hover {
                color: $text-color;
              }
            }
          }
          .accordion-button {
            &::after {
              background-image: none;
              color: $text-color;
              fill: $text-color;
            }
            &:not(.collapsed) {
              color: $text-color;
              &::after {
                background-image: none;
                transform: rotate(90deg) !important;
              }
            }
          }
        }
      }
    }
  }
  .sidenav-icon {
    width: 1rem;
    height: 1rem;
    margin-right: calc(0.625 * var(--base-spacing));
    fill: $icon-color;
    margin-top: calc(-0.0875 * var(--base-spacing));
  }
  .accordion-collapse {
    .sidenav-icon {
      margin-right: calc(0.3125 * var(--base-spacing));
      vertical-align: top;
      margin-top: calc(0.0875 * var(--base-spacing));
      display: none;
    }
  }
}
// .alert-categories-filter {
//     margin-top: calc(-.15 * var(--base-spacing));
// }
//others
.user-section {
  position: relative;
  min-height: 25rem;
  h6 {
    @include media-breakpoint-down(md) {
      padding-left: calc(0.85 * var(--base-spacing));
    }
  }
  .card-no-data {
    width: calc(100% - 24px);
  }
}
.accordion {
  .auto-dropdown-menu {
    .auto-dropdown-menu-item {
      word-break: break-all;
    }
  }
}
.poll-question-label {
  color: $grey-text-color;
}
.generic-error-msg {
  position: relative;
  width: 100%;
  margin-top: calc(5 * var(--base-spacing));
  text-align: center;
}
//card
.card {
  border-radius: $border-radius;
  background-color: $layout-bg;
  // overflow: hidden;
  .card-header {
    padding-bottom: calc(0 * var(--base-spacing));
    padding-left: calc(1 * var(--base-spacing));
    padding-right: calc(1 * var(--base-spacing));
    border-radius: $border-radius;
    border: none;
    font-size: calc(1.125 * var(--base-font-size));
    font-weight: $card-header-weight;
  }
  .card-body {
    padding-top: 0;
    padding-left: $card-body-spacer-x;
    padding-right: $card-body-spacer-x;
    .metrics {
      font-family: $headings-font-family-bold;
      font-size: calc(2.25 * var(--base-font-size));
    }
    .metrics-summary,
    .metrics-negative,
    .metrics-positive,
    .metrics-neutral {
      font-size: calc(1.125 * var(--base-font-size));
    }
    .metrics-summary,
    .metrics-neutral {
      color: $grey-text-color;
    }
    .card-merices {
      margin-top: calc(-0.875 * var(--base-spacing));
      padding-left: $card-spacer-x;
      font-weight: $metrices-weight;
      @include media-breakpoint-down(md) {
        padding-left: 0.75rem;
      }
    }
    .btn-help {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
  .dropdown {
    .card-dropdown-btn {
      margin-right: calc(-0.5625 * var(--base-spacing));
      margin-top: calc(-0.35 * var(--base-spacing));
      color: $interactive-color;
      border: none;
      box-shadow: none;
      background-color: transparent;
    }
    &.show {
      .card-dropdown-btn {
        background-color: transparent;
        box-shadow: none;
      }
    }
    .dropdown-menu {
      border: none;
      .dropdown-item-download {
        text-align: right;
        .svg-icon-nav-list {
          margin-left: 0 !important;
        }
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
  table {
    th,
    td {
      @include media-breakpoint-down(md) {
        padding-left: calc(1 * var(--base-spacing)) !important;
        padding-right: calc(1 * var(--base-spacing)) !important;
      }
    }
  }
}
.no-data-min-height {
  min-height: 10rem;
  position: relative;
}
//RGL
.react-grid-layout {
  @include media-breakpoint-down(md) {
    min-height: 5rem !important;
    height: auto !important;
    .react-grid-item {
      min-height: 5rem !important;
      height: auto !important;
      position: relative !important;
      transform: none !important;
      width: calc(100% - 1rem) !important;
      margin-left: var(--base-spacing);
      margin-bottom: calc(-0.625 * var(--base-spacing));
      .card-body {
        min-height: 12rem;
        .loader-center-align {
          margin-top: 0 !important;
        }
      }
      // .no-data,
      // .card-no-data,
      // .no-data-matrices {
      //     position: relative;
      // }
    }
  }
}
.visitor-analytics-modal,
.visitor-analytics-activity-modal {
  &.modal-body {
    min-height: 20rem;
  }
}
.visitor-analytics-modal .trafficContent {
  .card-no-data {
    top: 0 !important;
    position: relative;
    margin-bottom: calc(3 * var(--base-spacing));
  }
}
.modal-dialog {
  .modal-body {
    //min-height: 20rem;
    .react-grid-layout {
      height: auto !important;
    }
  }
}
.modal-session-timeout {
  .modal-dialog {
    .modal-body {
      min-height: auto !important;
    }
  }
}
.modal-backdrop {
  z-index: 9999;
  @include media-breakpoint-down(md) {
    z-index: 99999;
  }
}
.modal {
  z-index: 9999;
  @include media-breakpoint-down(md) {
    z-index: 9999999;
    &+.modal-backdrop {
      z-index: 9999999;
    }
  }
}
.react-grid-item {
  &.col-full-width {
    min-height: 5rem !important;
    height: auto !important;
    position: relative !important;
    transform: none !important;
    width: 100% !important;
    margin-bottom: calc(-0.625 * var(--base-spacing));
    .no-data,
    .card-no-data,
    .no-data-matrices {
      position: relative;
    }
  }
}
//accordion
.accordion-button {
  padding: $form-control-padding;
  font-size: var(--body-font-size);
  &::after {
    background-size: 1rem;
  }
}
//dropdown
.dropdown {
  .btn {
    min-height: 40px;
    padding: $form-control-padding;
    font-size: var(--body-font-size);
    font-weight: $font-weight-normal;
    border-color: $outline-color;
    background-color: $layout-bg;
  }
  .dropdown-menu {
    max-height: 15rem;
    overflow-x: hidden;
    overflow-y: auto;
    .dropdown-item {
      font-size: var(--body-font-size);
      font-weight: $font-weight-normal;
      color: $text-color;
      white-space: normal;
      &:hover {
        background-color: $id-bg-grey-c;
      }
    }
    border: none;
    &.dashboard-dropdown {
      z-index: 99;
    }
  }
  option {
    padding: $select-menu-item-spacing;
  }
  .dropdown-primary-icon {
    width: 0.9375rem;
    height: 0.9375rem;
    fill: $icon-color;
    color: $icon-color;
    //margin-top: calc(-.3 * var(--base-spacing));
  }
  .dropdown-toggler {
    float: right;
    margin-top: calc(0.1875 * var(--base-spacing));
    color: $interactive-color;
  }
  .is-disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
    &:focus {
      box-shadow: none;
    }
  }
  &.full-width {
    .btn {
      width: 100%;
      text-align: left;
      .dropdown-toggler {
        float: right;
        margin-top: calc(0.25 * var(--base-spacing));
      }
    }
    .dropdown-menu {
      width: 100%;
    }
    &.text-overflow {
      .btn {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }
  &.text-overflow {
    .btn {
      min-width: 17rem;
      max-width: 17rem;
      &>span {
        width: 88%;
        @include media-breakpoint-down(lg) {
          width: 80%;
        }
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        float: left;
      }
    }
  }
}
//edit profile dropdown
.edit-profile-container {
  display: inline-block;
  vertical-align: middle;
  .edit-profile-labels,
  .edit-profile-tigger {
    display: table-cell;
    vertical-align: middle;
  }
  .edit-profile-tigger {
    @include media-breakpoint-down(md) {
      padding-top: calc(1 * var(--base-spacing));
    }
    .icon-nav-box {
      margin-top: calc(-0.1875 * var(--base-spacing));
    }
  }
}
.edit-profile-labels {
  @include media-breakpoint-down(md) {
    float: left;
    width: calc(100% - 1.5rem);
  }
}
.edit-profile-tigger {
  //margin-top: calc(.625 * var(--base-spacing));
  //float: right;
  cursor: pointer;
  .edit-profile-tigger-icon {
    color: $interactive-color;
  }
}
.filter-btn {
  min-width: 11rem;
}
.filter-box-tigger {
  cursor: pointer;
  .filter-box-tigger-icon {
    color: $interactive-color;
  }
}
.icon-nav-list {
  color: $interactive-color;
  fill: $interactive-color;
  .icon-nav-box {
    margin-left: calc(0.5 * var(--base-spacing));
    float: left;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      box-shadow: none;
    }
    .btn-group {
      .dropdown-toggle {
        margin-top: calc(0.125 * var(--base-spacing)) !important;
        background: none !important;
        box-shadow: none !important;
        color: $interactive-color !important;
      }
    }
  }
  .icon-nav {
    float: left;
    color: currentColor;
    fill: currentColor;
    &.icon-nav-filter {
      margin-top: calc(0.05 * var(--base-spacing));
    }
  }
}
.svg-icon-nav-list {
  .icon-nav {
    width: 1.125rem;
    height: 1.125rem;
    float: left;
    color: $interactive-color;
    
    &.common-icon-color {
      color: $text-color;
    }
    &.interactive-icon-color {
      color: $interactive-color;
      &:hover{
        color: $id-blue-lighter;
      }
    }
    &.icon-nav-filter {
      margin-top: calc(0.15 * var(--base-spacing));
    }
  }
}
.managesubscription-table {
  .svg-icon-nav-list {
    .icon-nav {
      &.icon-nav-filter {
        margin-top: 0;
        margin-right: calc(-0.1875 * var(--base-spacing));
      }
    }
    .fa-plus-circle {
      width: 1rem !important;
      height: 1rem !important;
    }
  }
}
.search-box-container {
  position: absolute;
  right: calc(1.5625 * var(--base-spacing));
  bottom: calc(-1.5625 * var(--base-spacing));
  &.modal-search-box {
    right: calc(0.75 * var(--base-spacing)) !important;
  }
}
.table-search-box {
  .input-group {
    .input-search {
      padding-right: calc(2 * var(--base-spacing)) !important;
      padding-top: 0.65rem;
      padding-bottom: 0.45rem;
    }
    .btn-search-reset {
      border: none !important;
      background: none;
      position: absolute;
      right: 0.25rem;
      color: $id-grey-3;
      z-index: 3;
      top: calc(0.17 * var(--base-spacing));
    }
  }
}
.watchlist-btn-search-reset {
  border: none !important;
  background: none;
  position: absolute;
  right: 0.25rem;
  color: $id-grey-3;
  z-index: 3;
  top: calc(0.17 * var(--base-spacing));
}
.watchlist-searchbox-wrapper {
  .search-box-container {
    @include media-breakpoint-down(sm) {
      position: relative;
      width: calc(100% - var(--base-spacing));
      right: auto;
      left: 0;
      bottom: auto;
      margin-bottom: calc(1.25 * var(--base-spacing));
    }
  }
}
.filter-container {
  width: 25rem !important;
  right: 0 !important;
  margin-right: 3.75rem !important;
}
.webcast-questions-card-nav {
  .filter-container {
    @include media-breakpoint-down(md) {
      width: calc(100% - 5rem) !important;
      margin-right: 4.5rem !important;
    }
  }
}
.webcast-viewers-filter-box {
  margin-right: calc(0.5 * var(--base-spacing)) !important;
  width: 18rem !important;
}
.webcast-sync-icon {
  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
    margin-top: calc(0.1 * var(--base-spacing)) !important;
  }
}
.webcast-viewers-data {
  .table {
    th,
    td {
      padding-left: calc(0.75 * var(--base-spacing));
      padding-right: calc(0.75 * var(--base-spacing));
      word-break: normal !important;
      &:first-child {
        padding-left: calc(1.5 * var(--base-spacing));
        word-break: break-all !important;
      }
      &:nth-child(2) {
        word-break: break-all !important;
      }
    }
  }
}
.webcast-table-data,
.webcast-viewers-data {
  .preloader,
  .no-data {
    top: 55% !important;
    @include media-breakpoint-down(md) {
      top: 60% !important;
    }
  }
}
.editable-pages-loader {
  top: 40% !important;
  left: 60% !important;
}
// .webcast-table-data{
//     margin-top: calc(-5 * var(--base-spacing));
//     @include media-breakpoint-down(md) {
//         margin-top: calc(-1.5 * var(--base-spacing));
//     }
// }
.webcast-table-nav {
  margin-top: calc(1.5 * var(--base-spacing));
  @include media-breakpoint-down(md) {
    margin-top: calc(1 * var(--base-spacing));
  }
  .icon-nav-list {
    .icon-nav-box {
      //margin-top: calc(-0.0625 * var(--base-spacing));
      .btn-group {
        .dropdown-toggle {
          min-height: auto;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
.service-filter {
  .filter-container {
    margin-right: 1rem !important;
    margin-top: 1.75rem !important;
  }
  .table-search-box {
    padding-right: calc(1.5 * var(--base-spacing));
    @include media-breakpoint-down(md) {
      width: calc(100% - 1rem);
      padding-right: calc(0.5 * var(--base-spacing));
    }
    .form-control {
      width: auto;
    }
  }
}
.service-center-table-modal {
  min-height: 10rem;
}
.modal-dialog {
  .service-filter {
    .table-search-box {
      padding-right: calc(0.5 * var(--base-spacing));
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }
  }
  &.modal-xl {
    &.modal-dialog-centered {
      @include media-breakpoint-down(md) {
        padding-top: calc(2 * var(--base-spacing));
      }
    }
  }
}
.reporthistory-table {
  .table {
    th,
    td {
      &:last-child {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
}
//account profile drop down
.slider-dropdown {
  .slider-dropdown-tigger {
    min-height: 40px;
    padding: $form-control-padding;
    font-size: var(--body-font-size);
    font-weight: $font-weight-normal;
    border-color: $outline-color;
    background-color: $layout-bg;
    color: $icon-color;
    &:hover,
    &:focus,
    &:active {
      background-color: $layout-bg;
      color: $interactive-color;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .dropdown-menu {
    max-height: none !important;
    .dropdown-item {
      color: $text-color;
      &:hover {
        background-color: transparent;
      }
    }
    .btn {
      &.btn-primary {
        background-color: $primary;
        &:hover {
          background-color: $secondary;
        }
      }
    }
  }
  .slider-dropdown-subtitle {
    margin-top: calc(2.5 * var(--base-spacing));
    font-family: $font-family-base;
    font-size: calc(1.125 * var(--base-font-size));
    font-weight: var(--font-weight-semi-bold);
  }
  .slider-dropdown-name {
    font-weight: var(--font-weight-semi-bold);
  }
  a {
    color: $interactive-color;
  }
}
//Reports
.report-container {
  margin: 0;
  padding: 0;
  height: calc(100vh - 245px);
}
//webcast
.table {
  .question-count {
    font-size: calc(1 * var(--body-font-size));
    font-weight: var(--body-font-weight);
    color: rgba($id-deep-indigo, 0.85);
  }
  .poll-question {
    font-size: calc(1.25 * var(--body-font-size));
    font-weight: var(--font-weight-semi-bold);
    color: $id-deep-indigo;
    @include media-breakpoint-down(md) {
      font-size: calc(1 * var(--body-font-size));
    }
  }
  .dropdown {
    .btn {
      min-width: 13rem;
      padding: $table-dropdown-paddng;
      min-height: 1.875rem;
      font-size: calc(0.875 * var(--body-font-size));
      span {
        width: 83%;
      }
    }
    .dropdown-menu {
      min-width: 100%;
      .dropdown-item {
        font-size: calc(0.875 * var(--body-font-size));
      }
    }
  }
}
.multi-poll-tab-content {
  .tab-content {
    max-height: 26rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.modal-dialog {
  .tab-pane {
    .table-responsive-lg {
      min-height: 8rem;
    }
    .card-no-data {
      top: 30% !important;
    }
    &.trafficContent {
      .card-no-data {
        left: 0 !important;
      }
    }
  }
  .card-no-data {
    left: 0 !important;
  }
}
.service-centre-report-detail-modal {
  min-height: 10rem;
  position: relative;
  .card-no-data {
    // position: relative !important;
    //top: 0% !important;
  }
}
.single-poll-tab-content {
  .table-responsive-lg {
    max-height: 28rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .border-top.border-bottom {
    @include media-breakpoint-down(md) {
      margin-top: calc(1 * var(--base-spacing)) !important;
    }
  }
}
.webcast-questions-card-nav {
  position: relative;
  // &.mt-n6 {
  //     @include media-breakpoint-down(md) {
  //         margin-top: -3rem !important;
  //     }
  // }
  .webcast-question-search-row {
    position: absolute;
    top: calc(4.81 * var(--base-spacing));
    right: 0;
    @include media-breakpoint-down(md) {
      position: relative;
      top: var(--base-spacing);
    }
    &.webcast-viewers-search {
      top: calc(3.25 * var(--base-spacing));
      right: var(--base-spacing);
      @include media-breakpoint-down(md) {
        width: 100%;
        top: calc(2 * var(--base-spacing));
        right: 0;
        margin-bottom: calc(2 * var(--base-spacing));
      }
    }
  }
  .webcast-question-search-container {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .table-search-box {
      @include media-breakpoint-down(md) {
        min-width: 100%;
        max-width: 13rem;
        width: 100%;
      }
    }
  }
}
.webcast-manage-users-search {
  @include media-breakpoint-down(md) {
    margin-top: calc(0.75 * var(--base-spacing)) !important;
    margin-bottom: calc(1.5 * var(--base-spacing));
  }
  .search-box-container {
    bottom: auto;
    top: calc(1.25 * var(--base-spacing));
    @include media-breakpoint-down(md) {
      right: 0;
      position: relative;
    }
  }
  .table-search-box {
    padding-right: 0;
    min-width: 355px;
    @include media-breakpoint-down(md) {
      padding-right: calc(1 * var(--base-spacing));
    }
  }
}
.question-container {
  max-height: 405px;
  padding-right: calc(0.5 * var(--base-spacing));
  overflow: hidden;
  overflow-y: auto;
}
.poll-question-txt {
  font-size: calc(1.5 * var(--body-font-size)) !important;
  @include media-breakpoint-down(md) {
    font-size: calc(1 * var(--body-font-size)) !important;
  }
}
.polls-refresh-button {
  @include media-breakpoint-down(md) {
    position: absolute;
    right: calc(0.75 * var(--base-spacing));
    top: calc(4.75 * var(--base-spacing));
  }
}
//button and dropdown
.btn-toolbar {
  .btn {
    font-size: var(--body-font-size);
    font-weight: $font-weight-normal;
    border-color: $outline-color;
  }
  .dropdown-menu {
    margin-top: calc(1 * var(--base-spacing)) !important;
  }
}
.btn {
  &.btn-help {
    background-color: transparent;
    &:focus {
      box-shadow: none;
    }
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
}
.editable-pages {
  .accordion-item {
    .btn {
      &.btn-help {
        font-weight: var(--font-weight-semi-bold);
        @include media-breakpoint-down(xl) {
          display: block;
        }
      }
    }
  }
}
//visitor analytics
.slider-dropdown-tigger {
  .fa-plus-circle {
    width: 0.9375rem !important;
    height: 0.9375rem !important;
    &.svg-inline--fa.fa-lg {
      vertical-align: inherit !important;
    }
  }
}
//chart
svg {
  path {
    &.domain {
      display: none;
    }
  }
  .tick {
    font-size: 0.75rem !important;
    line {
      color: $ui-lines;
    }
  }
  text {
    color: $text-color;
  }
}
//table
.table {
  thead {
    th {
      text-transform: none;
      font-weight: $font-weight-bold;
      border-bottom-color: $outline-color !important;
      white-space: normal;
      vertical-align: top;
      padding-left: calc(0.75 * var(--base-spacing)) !important;
      &:first-child {
        padding-left: calc(1.5 * var(--base-spacing)) !important;
      }
      @include media-breakpoint-down(lg) {
        white-space: nowrap;
      }
      button {
        vertical-align: top;
        padding-top: 0 !important;
      }
    }
    .sorting-label {
      display: inline-block;
      width: calc(100% - 0.875rem);
      &.header-summary-label {
        @include media-breakpoint-up(md) {
          min-height: 2.25rem;
        }
      }
    }
  }
  tbody {
    td {
      border: none;
      white-space: normal;
      .truncate-text {
        &.table-row-title {
          @include media-breakpoint-down(lg) {
            word-break: normal !important;
          }
        }
        &.table-row-title,
        &.table-row-subtitle {
          @include media-breakpoint-down(lg) {
            white-space: normal;
          }
        }
      }
      .card-link {
        i {
          font-size: var(--body-font-size);
        }
      }
      .form-switch {
        @include media-breakpoint-down(md) {
          min-width: 4.5rem;
        }
      }
    }
    border-bottom: 1px solid $outline-color !important;
  }
  // Styles for dark table
  .thead-dark {
    th {
      background-color: $layout-bg;
      color: $white;
      a {
        vertical-align: top;
        color: $dark;
      }
    }
  }
  // Styles for light table
  .thead-light {
    th {
      background-color: $layout-bg;
      color: $dark;
      a {
        vertical-align: top;
        color: $dark;
      }
    }
  }
  .table-row-title {
    font-weight: var(--font-weight-semi-bold);
    color: $dark;
  }
  .table-row-subtitle {
    color: $id-grey-3;
  }
  .table-truncate-text {
    .card-link {
      float: left;
      width: 100%;
    }
    @include media-breakpoint-down(md) {
      min-width: 10rem;
    }
    .truncate-text-container {
      max-width: 12.5rem !important;
      @include media-breakpoint-down(xl) {
        max-width: 8rem !important;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include media-breakpoint-down(md) {
        white-space: normal !important;
      }
    }
  }
  thead th,
  tbody td {
    padding-left: calc(0.75 * var(--base-spacing)) !important;
    padding-right: calc(0.75 * var(--base-spacing)) !important;
    word-break: normal !important;
    &:first-child {
      padding-left: calc(1.5 * var(--base-spacing)) !important;
      @include media-breakpoint-down(md) {
        padding-left: calc(1 * var(--base-spacing)) !important;
      }
    }
    @include media-breakpoint-down(md) {
      padding-left: calc(1 * var(--base-spacing)) !important;
    }
  }
}
.page-subtitle {
  font-family: var(--bs-font-sans-serif);
  font-size: calc(1.125 * var(--base-font-size));
  font-weight: var(--font-weight-semi-bold);
}
.contentinsights-table,
.contentinsight-table {
  // .card-body{
  //     min-height: 17rem !important;
  // }
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 23rem !important;
      }
    }
  }
  // .card-no-data, .no-data{
  //     position: relative;
  //     top: 90px;
  // }
  // .no-data{
  //     top: calc(2.5 * var(--base-spacing));
  // }
}
.contentinsight-table {
  .no-data {
    top: calc(4 * var(--base-spacing));
  }
}
.websitepane {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 30rem !important;
      }
    }
  }
}
.apppane {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 40rem !important;
      }
    }
  }
}
.manage-subscription-table-data {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 20rem !important;
      }
    }
  }
  .card-link+.card-link {
    margin-left: calc(0.75 * var(--base-spacing));
  }
}
.eventcalender-table {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 15rem !important;
      }
    }
  }
}
.reporthistory-table {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 18rem !important;
      }
    }
  }
}
.pages-table {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 28rem !important;
      }
    }
  }
}
.question {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 15rem !important;
      }
    }
  }
}
.question {
  .table {
    tr {
      td {
        &:nth-child(2) {
          &.table-truncate-text {
            .truncate-text-container {
              max-width: 100% !important;
              white-space: unset;
              overflow: visible;
            }
          }
        }
      }
    }
  }
}
.webcast-viewers-data {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 10rem !important;
      }
    }
  }
}
.watchlist-table {
  .table {
    .table-truncate-text {
      .truncate-text-container,
      .table-truncate-text {
        max-width: 12rem !important;
      }
    }
  }
}
.landingpage-table,
.exitpage-table {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 40rem !important;
      }
    }
  }
}
.search-table,
.documents-table {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 18rem !important;
      }
    }
  }
}
.visitorinsights-table {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 16rem !important;
      }
    }
  }
}
.visitorsContent,
.landing-page-content,
.exit-page-content {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 22rem !important;
      }
    }
  }
}
.trafficContent {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 35rem !important;
      }
    }
  }
}
.user-table {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 9rem !important;
      }
    }
  }
}
.service-centre-report-detail-modal {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 30rem !important;
      }
    }
  }
}
.webcastpane {
  .table {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 100% !important;
        white-space: inherit;
        float: left;
      }
    }
  }
}
.visitorinsights-table,
.watchlist-table,
.pages-table,
.landingpage-table,
.exitpage-table {
  .card-body {
    min-height: 10rem;
  }
}
.visitor-analytics-modal .trafficContent {
  .card-no-data {
    top: 0 !important;
    position: relative;
    margin-bottom: calc(3 * var(--base-spacing));
  }
}
//icon nav
.icon-nav {
  .nav-item {
    .nav-link {
      color: $interactive-color;
    }
  }
}
// tabs
.tab {
  border-bottom: $border-width solid $border-color;
  .nav-item {
    flex: none;
    margin-right: calc(1.25 * var(--base-spacing));
    margin-bottom: 0;
    border-bottom: none;
    .nav-link {
      padding: 0;
      min-height: 2.45rem;
      border: none;
      border-radius: 0;
      font-size: 1.125rem;
      font-weight: $font-weight-bold;
      &.active {
        position: relative;
        background: none;
        border-color: $border-color;
        &:after {
          background: $interactive-color;
          content: "";
          display: block;
          height: 0.1875rem;
          margin: 0 auto;
          position: relative;
          top: auto;
          left: 0;
          bottom: calc(-0.45 * var(--base-spacing));
        }
      }
    }
  }
  &.subtab {
    .nav-item {
      padding-right: 0;
      &:last-child {
        margin-right: 0 !important;
      }
      .nav-link {
        font-size: var(--base-font-size);
        &.active {
          &:after {
            bottom: calc(-0.9 * var(--base-spacing));
          }
        }
      }
    }
  }
  &.webcast-subtab {
    margin-bottom: 1rem;
    .nav-item {
      .nav-link {
        min-height: 2.25rem;
      }
    }
  }
}
// .nested-card-container{
//     display: inline-block;
//     .tab-content > .tab-pane{
//         float: left;
//     }
// }
//input chip
.react-tag-input {
  padding: calc(0.875 * var(--base-spacing));
  background: $id-white;
  color: rgba($id-black, 0.65);
  border: 1px solid $outline-color;
  border-radius: $border-radius;
}
.react-tag-input__input {
  background: transparent;
  color: rgba($id-black, 0.65);
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  color: rgba($id-black, 0.65);
  /* Input placeholder */
}
.react-tag-input__tag {
  background: rgba($interactive-color, 0.15);
  border: 1px solid rgba($interactive-color, 0.5);
}
.react-tag-input__tag__remove {
  background: transparent;
}
.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  background: $id-grey-3;
  /* X icon in remove button */
}
// pagination
.pagination {
  .page-item {
    .page-link {
      padding: 0.25rem 0.75rem;
      font-size: calc(0.9375 * var(--body-font-size));
      border: none;
      color: $text-color;
      &:hover{
        background: $id-blue-lighter;
        color: $id-white;
      }
    }
    &:first-child,
    &:last-child {
      .page-link {
        color: $interactive-color;
      }
    }
    &.active {
      .page-link {
        color: $id-white;
        z-index: 0;
      }
    }
    &.disabled {
      .page-link {
        color: $grey-text-color;
      }
    }
  }
}
//form
.form-container {
  padding: calc(1.5 * var(--base-spacing));
}
label {
  font-weight: $font-weight-normal;
}
.input-group-text {
  color: $text-color;
}
.focused {
  .input-group-text {
    color: $text-color;
  }
}
.form-control[type="file"] {
  color: $grey-text-color;
}
.form-label-spacing {
  padding-top: calc(0.5625 * var(--base-spacing));
}
.table-search-box {
  float: right;
  min-width: 300px;
  padding-right: calc(1 * var(--base-spacing));
  @include media-breakpoint-down(md) {
    float: none;
    min-width: 300px;
    width: 100%;
    padding-left: calc(1 * var(--base-spacing));
  }
}
.progress-wrapper {
  .progress-info {
    margin-bottom: calc(0.25 * var(--base-spacing));
    font-weight: var(--font-weight-semi-bold);
    .progress-label {
      color: $grey-text-color;
    }
    .progress-percentage {
      span {
        color: $grey-text-color;
        font-weight: var(--body-font-weight);
      }
    }
  }
  .progress {
    height: 0.5rem !important;
    width: auto !important;
    .progress-bar {
      background-color: $id-blue;
    }
  }
}
.poll-answer {
  width: 65% !important;
  @include media-breakpoint-down(md) {
    width: 55% !important;
  }
}
//card
.card-merices-container {
  display: inline-block;
  @include media-breakpoint-down(md) {
    font-size: calc(0.8125 * var(--body-font-size));
  }
}
.notification-header-dropdown {
  @include media-breakpoint-down(md) {
    padding-left: calc(0.75 * var(--base-spacing));
  }
}
.notification-table-search {
  @include media-breakpoint-down(md) {
    top: calc(-1 * var(--base-spacing));
    bottom: 0;
    position: relative;
    right: calc(1.25 * var(--base-spacing));
    left: auto;
    float: right;
  }
  .table-search-box {
    float: left;
    top: calc(2 * var(--base-spacing)) !important;
    @include media-breakpoint-down(md) {
      width: auto;
    }
  }
}
.card-dropdown-container {
  .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    top: calc(2.625 * var(--base-spacing)) !important;
    transform: none !important;
    min-width: 8rem;
    z-index: 99;
    span {
      width: 1.25rem;
    }
  }
  .btn {
    padding: 0;
    border: none;
    &:hover {
      color: $text-color;
    }
  }
  .dropdown-item {
    text-align: right;
    white-space: nowrap !important;
    @include media-breakpoint-down(md) {
      text-align: left;
    }
  }
}
// .webcast-table {
//     .table{
//         .dropdown-menu {
//             right: 0 !important;
//             left: auto !important;
//             transform: none !important;
//             min-width: 9rem;
//             top: calc(2.50 * var(--base-spacing)) !important;
//         }
//     }
// }
.webcast-download-nav {
  .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    // transform: none !important;
    min-width: 9rem;
    // top: calc(2.50 * var(--base-spacing)) !important;
  }
}
.widget-summary-metrics {
  font-size: calc(1.375 * var(--body-font-size));
  font-weight: bold;
  color: #4a4a4a;
  @include media-breakpoint-down(md) {
    font-size: calc(1.85 * var(--body-font-size));
  }
  &.metrics-1 {
    color: $chart-color-one;
  }
  &.metrics-2 {
    color: $chart-color-two;
  }
  &.metrics-3 {
    color: $chart-color-three;
  }
  &.metrics-4 {
    color: $chart-color-four;
  }
  &.metrics-5 {
    color: $chart-color-five;
  }
  &.metrics-6 {
    color: $chart-color-six;
  }
  &.metrics-7 {
    color: $chart-color-seven;
  }
}
.widget-summary-metrics-label {
  font-size: calc(0.875 * var(--body-font-size));
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  // &.label-1,
  // .label-2,
  // .label-3,
  // .label-4,
  // .label-5,
  // .label-6,
  // .label-7 {
  //   color: $id-deep-indigo;
  // }
  
  // &.label-2 {
  //     color: $chart-color-two;
  // }
  // &.label-3 {
  //     color: $chart-color-three;
  // }
  // &.label-4 {
  //     color: $chart-color-four;
  // }
  // &.label-5 {
  //     color: $chart-color-five;
  // }
  // &.label-6 {
  //     color: $chart-color-six;
  // }
  // &.label-7 {
  //     color: $chart-color-seven;
  // }
}
// Switch toggle component start
// .switch-toggle-component {
//   text-align: center;
//   .switch-toggle-component-item {
//     width: 33.333%;
//     text-align: center;
//     vertical-align: top;
//     @include media-breakpoint-down(md) {
//       width: 100%;
//       padding-left: calc(1 * var(--base-spacing));
//       padding-right: calc(1 * var(--base-spacing));
//       text-align: left;
//     }
//     .widget-summary-metrics,
//     .metrics-label-container {
//       width: 100%;
//       display: block;
//       @include media-breakpoint-down(md) {
//         width: 25%;
//         float: left;
//       }
//       @include media-breakpoint-down(sm) {
//         width: 50%;
//       }
//     }
//     .metrics-label-container {
//       @include media-breakpoint-down(md) {
//         width: 75%;
//       }
//       @include media-breakpoint-down(sm) {
//         width: 70%;
//       }
//       .widget-summary-metrics-label {
//         @include media-breakpoint-down(md) {
//           width: 35%;
//           float: left;
//           margin-top: calc(0.5 * var(--base-spacing));
//         }
//         @include media-breakpoint-down(sm) {
//           width: 50%;
//         }
//       }
//       form {
//         @include media-breakpoint-down(md) {
//           margin-top: calc(0.5 * var(--base-spacing));
//         }
//       }
//     }
//     .toggle-switch-container {
//       width: 100%;
//       @include media-breakpoint-down(sm) {
//         width: 30%;
//         float: left;
//         padding-top: calc(1 * var(--base-spacing)) !important;
//       }
//     }
//   }
//   &.webcast-stat {
//     min-height: 8rem;
//     @include media-breakpoint-down(sm) {
//       .metrics-label-container {
//         width: 100%;
//       }
//       .widget-summary-metrics-label {
//         width: 38% !important;
//       }
//       .widget-summary-metrics {
//         width: 30% !important;
//       }
//     }
//     .section-label {
//       font-size: calc(0.875 * var(--body-font-size)) !important;
//       @include media-breakpoint-down(sm) {
//         width: 30%;
//         float: left;
//         padding-top: calc(0.5 * var(--base-spacing)) !important;
//       }
//     }
//   }
// }
.switch-toggle-component {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .switch-toggle-component-item {
    padding: 10px 10px 25px 10px;
    text-align: center;
    flex: 1 1 calc(100% / 6 - 10px); /* Default: 6 items in one row */
    .widget-summary-metrics,
    .metrics-label-container {
      width: 100%;
      display: block;
    }
    .toggle-switch-container {
      width: 100%;
    }
  }
  /* If items > 6: First line - 4 items, Second line - 3 items */
  &:has(.switch-toggle-component-item:nth-child(7)) {
    .switch-toggle-component-item:nth-child(-n+4) {
      flex: 1 1 calc(100% / 4 - 10px);
    }
    .switch-toggle-component-item:nth-child(n+5):nth-child(-n+7) {
      flex: 1 1 calc(100% / 3 - 10px);
    }
  }
  /* If items > 9: First & Second line - 4 items each, Third line - 1 item */
  &:has(.switch-toggle-component-item:nth-child(10)) {
    .switch-toggle-component-item:nth-child(-n+4),
    .switch-toggle-component-item:nth-child(n+5):nth-child(-n+8) {
      flex: 1 1 calc(100% / 4 - 10px);
    }
    .switch-toggle-component-item:nth-child(9) {
      flex: 1 1 calc(100% - 10px);
    }
  }
  /* Webcast-stat styles */
  &.webcast-stat {
    min-height: 8rem;
    .section-label {
      font-size: calc(0.875 * var(--body-font-size)) !important;
    }
  }
}
// Switch toggle component End
//visitor-analytics-data start
.visitor-analytics-data{display: flex !important;justify-content: left;
  .card{width: auto !important;}}
//visitor-analytics-data end

// Notification Add button Start
.add-notification-text {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  // border: 1px solid #0068FF;
  // padding: 6px 30px;
  // border-radius: 8px;
  // background: #deeaff;
  // box-shadow: 0 5px 8px #deeaff;
}
// Notification Add button End

.total-summary-data {
  th {
    padding-top: calc(0.25 * var(--base-spacing)) !important;
    padding-bottom: calc(0.75 * var(--base-spacing)) !important;
  }
}
.tools-chart-renderer {
  &.switch-toggle-component {
    .switch-toggle-component-item {
      @include media-breakpoint-down(sm) {
        .metrics-label-container {
          width: 70%;
          .widget-summary-metrics-label {
            width: 75%;
          }
          .widget-summary-metrics {
            width: 25%;
            padding-left: calc(0.5 * var(--base-spacing));
          }
        }
        .toggle-switch-container {
          padding-top: calc(0.4 * var(--base-spacing)) !important;
        }
      }
    }
  }
}
#toolsInsights {
  .table {
    td {
      width: 12%;
      &:first-child {
        width: 32%;
      }
      &:nth-child(2) {
        width: 20%;
      }
    }
  }
  .tools-dashboard-card-loader {
    .table-truncate-text {
      .truncate-text-container {
        max-width: 18rem !important;
        @include media-breakpoint-down(md) {
          max-width: none !important;
        }
      }
    }
  }
}
.alert-statistis-chart-container-1 {
  .switch-toggle-component {
    .switch-toggle-component-item {
      width: 14.285%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
  .no-data,
  .card-no-data {
    top: 55%;
  }
}
.cookies-analytics-container {
  .cookies-common-metrices {
    @include media-breakpoint-down(lg) {
      margin-top: 0.5rem;
    }
    .metrices-row {
      @include media-breakpoint-down(lg) {
        margin-top: 0.5rem;
      }
    }
  }
  .switch-toggle-component-item {
    width: 100%;
  }
  .br-dddbdb {
    @include media-breakpoint-down(md) {
      border-right: none;
    }
  }
  .widget-summary-metrics-label {
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
}
.visitor-analytics-switch-toggle {
  .switch-toggle-component {
    .switch-toggle-component-item {
      width: 33.333%;
      vertical-align: top;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
      }
      .metrics-label-container {
        @include media-breakpoint-down(md) {
          margin-top: calc(0.5 * var(--base-spacing));
        }
        // .widget-summary-metrics-label{
        //     @include media-breakpoint-down(md) {
        //         margin-top: 0;
        //     }
        // }
        form {
          @include media-breakpoint-down(md) {
            margin-top: 0;
          }
        }
      }
      .toggle-switch-container {
        padding-top: 0; //calc(1 * var(--base-spacing));
      }
    }
  }
  .card {
    background: none;
    margin-bottom: 0 !important;
  }
  .card-body {
    padding-bottom: 0;
    min-height: 22rem;
  }
}
.page-analytics-switch-toggle {
  .switch-toggle-component {
    .switch-toggle-component-item {
      width: 50%;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-left: calc(1 * var(--base-spacing));
        padding-right: calc(1 * var(--base-spacing));
        text-align: left;
      }
    }
  }
}
.page-performance-detail {
  .switch-toggle-component {
    .switch-toggle-component-item {
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.page-analytics-summary-tooltip {
  .btn-help {
    position: absolute;
    z-index: 1;
    top: calc(2.81 * var(--base-spacing)) !important;
  }
  .table {
    th {
      position: relative;
      overflow-wrap: normal;
      .btn-help {
        top: calc(0.875 * var(--base-spacing)) !important;
      }
    }
  }
}
.table {
  th {
    .btn-help {
      padding-top: calc(0.15 * var(--base-spacing)) !important;
      font-size: calc(0.75 * var(--body-font-size)) !important;
    }
  }
}
// Webcast-analytics CSS Start
.webcast-analytics .switch-toggle-component {
  display: flex;
  justify-content: space-between;
}
.webcast-analytics .switch-toggle-component .switch-toggle-component-item {
  width: auto;
}
.webcast-analytics .tab .nav-item .nav-link {
  min-height: 2.25rem;
}
.webcast-analytics .half-width-card-container .card,
.webcast-analytics .card-body {
  min-height: 28rem !important;
}
.webcast-analytics .table-hover>tbody>tr:hover:not(:has(table)) {
  background: $white;
  color: text-color;
}
.webcast-analytics .table-hover table {
  pointer-events: none;
  z-index: 999;
}
.webcast-analytics .table-striped table tr:nth-of-type(even) {
  background: $white;
  &:hover {
    background: $white;
    color: $text-color;
  }
}
.webcast-analytics .table-hover>tbody>tr table tr:nth-child(even),
.table-hover>tbody>tr:hover table tr:nth-child(even) {
  background: #ffffff;
  --bs-table-accent-bg: --bs-table-accent-bg;
  
}
.webcast-analytics .card .dropdown .card-dropdown-btn {
  margin: auto;
}
.webcast-analytics .webcast-viewers-filter-box {
  margin-right: calc(0.7 * var(--base-spacing)) !important;
}
.webcast-analytics .dropdown .btn {
  min-height: 1.3125rem;
}
.webcast-analytics .card-header-pills {
  margin: 0;
}
.webcast-analytics .webcast-page-refresh {
  cursor: pointer;
  height: 1.5rem !important;
  width: 1.5rem !important;
  margin-left: 0.2rem;
}
//MYIN-19566_SS Start
.alert .btn_close {
  position: relative;
  top: 0;
  left: 0;
}
//MYIN-19566_SS End
// Feedback popup css Start
.feedback-popupbox {
  .modal-header {
    padding: 0 0 1rem;
    margin-top: 0;
  }
  .form-label {
    margin-bottom: 0.25rem;
  }
}
// Feedback popup css End
//Date Range Css Start
.daterange-popup.modal-body {
  max-height: max-content;
}
.calendar-container {
  margin-top: -1rem;
  .left-column {
    border-right: 0.0625rem solid $id-grey-2;
  }
  .form-check {
    display: flex;
    float: none;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid $id-grey-2;
  }
  .custom-radio-button {
    padding-bottom: .5rem;
    margin-bottom: .5rem;
    border-bottom: 0.0625rem solid $id-grey-2;
  }
  .custom-tabs {
    padding: .4rem 0;
  }
  .form-check-input[type="radio"] {
    margin: 0.14rem 0.3rem 0 0;
    cursor: pointer;
  }
  #quick-select-section {
    .btn {
      color: $id-grey-3;
      background: none;
      border: 0;
      font-weight: 600;
      text-align: left;
      width: 100%;
      box-shadow: none;
      &:hover,
      &.active {
        color: $text-color !important;
      }
      &:focus {
        border: 0;
        outline: none;
      }
    }
  }
  .rdrMonthAndYearWrapper {
    padding: 0;
  }
  .form-select {
    border-color: $id-grey-2;
  }
  .rdrDateDisplayWrapper {
    background: none;
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrDayToday .rdrDayNumber span:after {
    background-color: #0068f0;
    bottom: 0;
  }
  .rdrDayNumber {
    top: 7px;
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    top: 2px;
  }
  .rdrDateDisplayItem {
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    border: 0;
    input {
      display: block;
      width: 100%;
      padding: 0.55rem 0.75rem;
      font-size: var(--body-font-size);
      font-weight: 400;
      line-height: 1.5;
      color: #514954;
      background: #fff;
      border: 0.0625rem solid #dddee2;
      border-radius: 0.3125rem;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: 2.5rem;
    }
  }
  .rdrDay {
    height: 1.7rem;
    line-height: normal;
  }
  .rdrDayPassive .rdrDayNumber span {
    color: $gray-600;
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    border: 0.0625rem solid #dddee2;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    border-radius: 4px;
    text-align: center;
    height: 35px;
    line-height: 1;
  }
  .rdrMonthAndYearPickers select,
  .rdrMonthName,
  .rdrWeekDays {
    color: #514954;
  }
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border-color: #088901;
  }
  .rdrCalendarWrapper {
    font-size: 13px;
  }
}
//Date Range Css End
// Cookie Page CSS start
.cookie-page {
  p {
    font-size: large;
  }
}
// Cookie Page CSS start
// Enable button BG color start
.btn-danger{background: $red !important;
&:hover{background: lighten($red, 0.8);}}
.btn-enable{background: $green !important;
&:hover{background: lighten($green, 0.8) !important;}}
//Enable button BG color end
//Pie chart and legend's css start
// g.legendHolder {
//   display: inline-block;
//   position: relative;
//   transform:translateX(30px);
//   word-break: normal;
//   overflow-wrap: break-word;
//   white-space: normal;
//   .legend{
//     max-width: 80px;
//     width: 100%;
//   text{
    
//     word-break: normal;
//     overflow-wrap: break-word;
//     white-space: normal;}
//   }
// }
// .legendHolder {
//   width: 150px; /* Apni desired width set karein */
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   transform: translateX(25px);
//   transform: scale(1);
// }

// .legend text {
//   white-space: break-spaces; /* White-space ke basis par break karega */
//   word-break: break-word; /* Long words ko bhi break karega */
//   overflow-wrap: break-word; /* Ensure karega ki text wrap ho */
// }


//Pie chart and legend's css end

@media (min-width: 768px) {
  .webcast-analytics .row .col-md-12:last-child {
    padding-right: calc(var(--bs-gutter-x) / 2) !important;
    padding-left: calc(var(--bs-gutter-x) / 2) !important;
  }
}
.webcast-analytics .webcast-table-nav {
  margin-top: 0;
}
.webcast-questions-card-nav .webcast-question-search-row {
  top: calc(var(--base-spacing)* 2.81);
}
// Webcast-analytics CSS CSS End
//Tools overview
.tools-overview-subtab {
  .nav-link {
    display: inline-block;
    width: 100%;
    padding: 0 !important;
    min-height: 4.6875rem !important;
    &.active {
      &:after {
        bottom: calc(-0.1 * var(--base-spacing)) !important;
      }
    }
    .tools-tab-label-text {
      //min-height: 4.6875 !important;
    }
    .tools-tab-count {
      font-size: calc(2.25 * var(--body-font-size)) !important;
    }
  }
}
.table-column-count {
  // padding-left: calc(.71875 * var(--base-spacing));
  padding-top: calc(0.25 * var(--base-spacing));
  font-size: calc(0.9375 * var(--body-font-size)) !important;
  color: $grey-text-color;
  @include media-breakpoint-down(md) {
    float: left;
  }
}
.half-width-card-container {
  .card {
    min-height: 32rem !important;
  }
}
.tnf-insights-modal {
  .search-box-container {
    .table-search-box {
      padding-right: 0 !important;
    }
  }
  .tab-content {
    .tab-pane {
      p {
        margin-left: calc(0.25 * var(--base-spacing));
      }
    }
  }
}
//dropzone
.dropzone {
  border-color: $outline-color;
  &:hover {
    border-color: $interactive-color;
    background-color: $layout-bg;
  }
  .dz-message {
    .dz-button {
      font-size: var(--base-font-size);
      font-weight: var(--body-font-weight);
      color: $interactive-color;
    }
    .file-upload-info {
      font-size: var(--body-font-size);
      color: $grey-text-color;
    }
    .file-upload-icon {
      svg {
        height: 2.5rem;
        fill: $interactive-color;
      }
    }
  }
}
.dropzone-files {
  margin-top: calc(1 * var(--base-spacing));
}
.dropzone-preview {
  min-height: 2rem;
}
.dropzone-filename {
  margin: 0;
  font-weight: var(--font-weight-semi-bold);
}
//datepicker
.input-group {
  .input-group-text {
    color: $interactive-color;
  }
  .form-control {
    &+.input-group-text {
      background-color: none !important;
      border-right: $input-border;
    }
  }
  .form-control {
    &+.input-group-text {
      background-color: none !important;
      border-right: $input-border;
    }
  }
}
.date-range-container,
.filter-container {
  padding: 0.35rem 1rem 1rem;
  width: calc(100% - 1.5rem);
  max-width: 25rem;
  right: calc(0.75 * var(--base-spacing));
  margin-top: calc(0.0625 * var(--base-spacing));
  position: absolute;
  z-index: 5;
  background: $layout-bg;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  @include media-breakpoint-down(md) {
    top: calc(2.5 * var(--base-spacing));
    left: var(--base-spacing);
    z-index: 99999;
  }
  // .date-range-container {
  //     bottom: 45px;
  // }
  color: $text-color;
  .rdtPicker {
    right: 0;
    top: 32px;
  }
}
.date-range-container {
  z-index: 998;
}
.filter-container {
  .date-range-container {
    bottom: 45px;
  }
}
.header-custom-date-range {
  &.date-range-container {
    bottom: 45px;
    box-shadow: $date-range-box-shadow !important;
  }
}
.service-hour {
  .date-range-container {
    @include media-breakpoint-down(md) {
      width: 23.75rem !important;
      left: calc(1 * var(--base-spacing)) !important;
      right: calc(1.25 * var(--base-spacing)) !important;
    }
  }
}
.service-period {
  text-align: right;
  @include media-breakpoint-down(md) {
    text-align: left;
  }
}
.service-hour-calculation {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.rdtPicker {
  min-width: 12.5rem;
  font-size: calc(0.8125 * var(--base-font-size));
  font-weight: var(--font-weight-semi-bold);
  .dow {
    height: 2rem;
    line-height: 2rem;
    font-size: calc(0.8125 * var(--base-font-size));
  }
  td {
    height: 1.5rem !important;
    &.rdtActive {
      background-color: $interactive-color !important;
      color: $id-white !important;
    }
    &.rdtToday {
      background-color: rgba($interactive-color, 0.15);
      color: $interactive-color;
      &:hover {
        background-color: rgba($interactive-color, 0.15);
        color: $interactive-color;
      }
    }
  }
  thead {
    th {
      font-weight: $font-weight-bold;
      &.dow {
        font-weight: $font-weight-bold;
      }
      &.rdtNext,
      &.rdtPrev {
        line-height: 1.4375rem;
        color: $interactive-color;
      }
    }
  }
}
.rdt {
  .input-group-text,
  .form-control:focus {
    &.is-valid {
      border-color: $success;
    }
    &.is-invalid {
      border-color: $danger;
    }
  }
}
.form-check {
  float: left;
  margin-top: calc(0.5 * var(--base-spacing));
  margin-right: calc(2.5 * var(--base-spacing));
  .form-check-label {
    line-height: 1.35rem;
  }
}
.single-validation-error {
  float: left;
}
//button
.btn {
  padding: 0.66rem 1.125rem;
  font-size: var(--body-font-size);
  font-weight: $font-weight-normal;
  box-shadow: none;
  &.btn-sm {
    padding: 0.25rem 0.65rem;
  }
  &.btn-lg {
    padding: 0.78rem 1.35rem;
  }
}
.btn-primary {
  border-color: $interactive-color;
  color: $id-white;
  &:hover,
  &:focus {
    background-color: $id-blue-lighter;
    border-color: $id-blue-lighter;
    color: $id-white;
  }
}
.btn-outline-primary {
  &:hover {
    background-color: rgba($interactive-color, 0.15);
    color: $interactive-color;
  }
}
.btn-icon-only {
  padding: 0.5rem;
  background: none;
  border: none;
  color: $interactive-color;
  &:hover {
    background: none;
    color: $interactive-color;
  }
  &:focus,
  &:active {
    outline: none;
    background: none;
    border: none;
    color: $interactive-color;
    box-shadow: none;
  }
}
.btn-icon-text {
  color: rgba($text-color, 0.85);
  svg {
    color: $text-color !important;
  }
  &:hover {
    color: $interactive-color;
    fill: $interactive-color;
    svg {
      color: $interactive-color !important;
    }
  }
}
.btn {
  &.table-btn {
    font-size: calc(0.875 * var(--body-font-size)) !important;
  }
}
.dropdown {
  .dropdown-toggle {
    text-align: left;
  }
}
//account
#mainnav {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}
.account-dropdown {
  &.btn {
    min-width: auto !important;
    min-height: 1rem;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      outline: none;
      box-shadow: none;
    }
  }
}
.account-dropdown,
.header-logout-btn {
  &.btn {
    min-width: auto !important;
    min-height: 1rem;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      outline: none;
      box-shadow: none;
    }
  }
}
.header-logout-btn {
  padding: calc(0.55 * var(--base-spacing)) calc(0.8 * var(--base-spacing));
  border-color: $outline-color;
  color: $text-color;
}
.header-edit-btn-container {
  .header-edit-btn {
    text-transform: uppercase;
    font-family: $headings-font-family;
    padding: .55rem 2.5rem;
    border-radius: 1.25rem;
    .right-icon {
      margin-left: 7px;
    }
    &:hover {
      background-color: #0068f0;
      color: $id-white !important;
    }
  }
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}
//badge
.badge {
  font-weight: var(--font-weight-semi-bold);
  line-height: calc(0.65 * var(--base-spacing));
}
.badge-active,
.bg-active {
  background-color: $success;
}
.badge-inactive,
.bg-inactive {
  background-color: $id-grey-3;
  &.bg-light {
    background-color: $id-grey-3 !important;
  }
}
//modal
.modal-header {
  padding-bottom: 0;
  border-bottom: none;
  align-items: flex-start;
  .modal-title {
    font-family: var(--bs-font-sans-serif);
    font-size: calc(1.25 * var(--base-font-size));
    font-weight: var(--font-weight-semi-bold);
  }
  .btn-close {
    fill: $interactive-color;
  }
}
.modal-body {
  max-height: 33.125rem;
  overflow-y: auto;
  font-size: var(--body-font-size);
}
.modal-footer {
  padding-top: 0;
  border: none;
  &>* {
    margin: 0;
  }
}
//session time out
.modal-session-timeout {
  z-index: 99999;
  @include media-breakpoint-down(md) {
    z-index: 9999999;
  }
  .modal-content {
    padding: 1.875rem 4.5rem 2rem;
    @include media-breakpoint-down(md) {
      padding: 1.5rem 1rem;
    }
  }
  &.session-expired {
    &.fade {
      backdrop-filter: blur(0.35rem); // Screen blur When Session time out.
      z-index: 99999;
      @include media-breakpoint-down(md) {
        z-index: 9999999;
      }
    }
  }
}
.session-time {
  font-size: calc(4 * var(--body-font-size));
  color: $interactive-color;
  @include media-breakpoint-down(md) {
    font-size: calc(1.5 * var(--body-font-size));
  }
  .session-time-icon,
  .session-time-txt {
    float: left;
  }
  .session-time-txt {
    color: $text-color;
    @include media-breakpoint-down(md) {
      &.mt-3 {
        margin-top: 0 !important;
      }
    }
  }
  .session-time-icon {
    height: 2.5rem;
    margin-top: calc(-0.25 * var(--base-spacing));
    @include media-breakpoint-down(md) {
      height: 1.5rem;
      margin-top: calc(-0.15 * var(--base-spacing));
    }
    &.session-expired-icon {
      line-height: normal;
      margin-top: calc(0.5 * var(--base-spacing));
    }
  }
}
//message
.note-msg {
  font-size: calc(0.875 * var(--body-font-size));
}
//autoComplete
.auto-dropdown {
  position: relative;
  width: 100%;
  .auto-dropdown-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 12.5rem;
    overflow-y: auto;
    padding: 0.55rem 0.75rem;
    z-index: 1;
    background: $layout-bg;
    box-shadow: $box-shadow;
    text-align: left;
    .auto-dropdown-menu-item {
      font-size: var(--body-font-size);
      cursor: pointer;
    }
  }
}
main {
  &.content {
    padding-left: 0;
  }
  // .website-analytics,
  //.webcast-analytics,
  .alert-analytics {
    .react-grid-layout {
      width: calc(100% - 15px) !important;
    }
  }
  .alert-analytics {
    .table {
      td {
        @include media-breakpoint-down(xl) {
          &:first-child {
            min-width: 10rem;
            padding-right: calc(0.5 * var(--base-spacing)) !important;
          }
          &:nth-child(2) {
            min-width: 6rem;
            padding-right: calc(0.5 * var(--base-spacing)) !important;
          }
        }
      }
    }
  }
  .manage-events-data {
    .card {
      height: auto !important;
      width: auto !important;
      margin-left: calc(1 * var(--base-spacing));
      .card-body {
        min-height: 20rem;
        @include media-breakpoint-down(md) {
          min-height: 22rem;
        }
        .no-data,
        .card-no-data {
          top: calc(3 * var(--base-spacing)) !important;
        }
      }
      &.eventcalender-table {
        .card-body {
          .no-data,
          .card-no-data {
            top: 60% !important;
            @include media-breakpoint-down(md) {
              top: 70% !important;
            }
          }
        }
      }
    }
    .table {
      td {
        @include media-breakpoint-down(lg) {
          &:nth-child(3),
          &:nth-child(5) {
            min-width: 10rem;
            padding-right: calc(0.5 * var(--base-spacing)) !important;
          }
        }
      }
    }
    .filter-container {
      z-index: 9;
      margin-right: 0 !important;
      right: 1rem !important;
      @include media-breakpoint-down(md) {
        width: calc(100% - 2rem) !important;
        right: 2rem !important;
        top: 11rem !important;
      }
    }
  }
  // .react-grid-layout{width: calc(100% - 15px) !important;}
}
.form-control {
  &.input-search {
    border-right: $input-border !important;
    padding-right: inherit !important;
    border-top-right-radius: inherit !important;
    border-bottom-right-radius: inherit !important;
  }
}
.input-right-border {
  border-right: $input-border !important;
  padding-right: inherit !important;
  border-top-right-radius: inherit !important;
  border-bottom-right-radius: inherit !important;
}
.parentCell:hover>span.tooltip {
  display: block;
}
//tooltip
.tooltip {
  padding: $tooltip-padding;
  font-size: $tooltip-fontsize;
  border-radius: $border-radius;
  background-color: $id-white;
  color: $text-color;
  border: 1px solid $ui-lines;
  @include media-breakpoint-down(md) {
    z-index: 9999999 !important;
  }
  // box-shadow: $box-shadow-inset;
  &[role="tooltip"] {
    padding: 0;
    border: none;
    border-radius: 0;
    .tooltip-inner {
      text-align: left;
    }
    .bs-tooltip-top {
      .tooltip-arrow {
        bottom: -1rem;
      }
    }
  }
}
.mobile-tooltip {
  max-width: 12rem !important;
  @include media-breakpoint-down(md) {
    width: 5rem !important;
    height: auto !important;
    padding: calc(0.35 * var(--base-spacing));
  }
}
//Alerts
.add-subscriber {
  max-height: 278px;
  overflow: hidden;
  overflow-y: auto;
  .form-check {
    width: 50%;
    margin-right: 0;
  }
  .form-check-label {
    width: calc(100% - 25px);
  }
}
//login
.login-box {
  position: absolute;
  top: 50%;
  margin-top: calc(-16.25 * var(--base-spacing));
  @include media-breakpoint-down(md) {
    margin-top: calc(-14.75 * var(--base-spacing));
  }
  background-color: $layout-bg;
}
.login-page-title {
  font-size: calc(1.125 * var(--body-font-size));
  font-weight: var(--font-weight-semi-bold);
}
.login-page-logo {
  width: 270px;
}
.blurbackground {
  height: 100%;
  width: 100%;
  z-index: 9999;
  position: fixed;
  //opacity: 0.5;
  background: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  &.service-center-modal-backdrop {
    background: rgba(255, 255, 255, 0.5);
    z-index: 99999 !important;
    @include media-breakpoint-down(md) {
      z-index: 99999999 !important;
    }
  }
}
//alert
.alert {
  padding: calc(0.5625 * var(--base-spacing));
  padding-left: var(--base-spacing);
  font-size: var(--body-font-size);
  color: $id-white;
  box-shadow: 0 2px 8px rgba($id-black, 0.2);
  border-radius: 0;
  &.alert-success {
    background-color: $id-black;
    border-color: $success;
    svg {
      color: $success;
    }
  }
  &.alert-warning {
    background-color: $id-black;
    border-color: $warning;
    svg {
      color: $warning;
    }
  }
  &.alert-danger {
    background-color: $id-black;
    border-color: $danger;
    svg {
      color: $danger;
    }
  }
  .btn-close {
    background: transparent url("../assets/Icon/alert-close.svg") center/2em auto no-repeat;
    opacity: 1;
  }
  &.toast-custom {
    position: fixed;
    top: 40%;
    right: 25%;
    left: 25%;
    z-index: 99999;
    @include media-breakpoint-down(md) {
      //top: 125px;
      right: calc(1.5 * var(--base-spacing));
      left: calc(1.5 * var(--base-spacing));
    }
  }
}
.hide-btn::after {
  display: none;
}
//accordion
.accordion {
  .accordion-item {
    margin-bottom: calc(0.5 * var(--base-spacing));
    border-radius: $border-radius;
    box-shadow: 0 0 4px rgba($id-black, 0.2);
    font-weight: normal;
    .accordion-button {
      padding: calc(0.75 * var(--base-spacing));
      font-size: var(--body-font-size);
      border: none;
      background: transparent;
      &::after {
        background-image: url(../assets/Icon/Small/Add-accordion.svg);
        color: $interactive-color;
        fill: $interactive-color;
      }
      &:not(.collapsed) {
        color: $text-color;
        &::after {
          background-image: url(../assets/Icon/Small/minus-accordion.svg);
          transform: none;
        }
      }
    }
    .accordion-collapse {
      border: none;
    }
    .accordion-body {
      padding: calc(0.75 * var(--base-spacing));
      padding-top: 0;
      a {
        color: $interactive-color;
        text-decoration: none;
      }
    }
  }
}
.editable-pages {
  .accordion {
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      ul {
        margin-bottom: 0;
      }
    }
    .accordion-item {
      font-size: var(--body-font-size);
      background: $layout-bg;
      a {
        color: $text-color;
      }
    }
    .accordion-button {
      font-weight: 500;
    }
    a {
      color: $text-color;
    }
    li {
      border-top: 1px solid $ui-lines;
      font-size: var(--body-font-size);
      padding: 0.75rem;
      padding-left: 1.5rem;
      display: block;
      &:hover {
        background-color: rgba($primary, 0.1);
      }
      span {
        &.disabled {
          opacity: 0.5;
          color: $grey-text-color;
          pointer-events: none;
        }
      }
    }
  }
}
//settings
.investis-team {
  line-height: calc(1.75 * var(--base-spacing));
  a {
    color: $interactive-color;
    &:hover {
      color: $interactive-color;
    }
    &.phone {
      color: $text-color;
      text-decoration: none;
      &:hover {
        color: $text-color;
      }
    }
  }
}
.my-documents,
.custom-card-container {
  .card {
    position: relative;
    .no-data {
      top: 35%;
    }
  }
}
.my-documents,
.custom-card-container,
.nested-card-container {
  .card {
    min-height: 17rem;
    border: none !important;
    box-shadow: none !important;
  }
}
.nested-card-container {
  background: $layout-bg;
  .card-header {
    padding: 0 !important;
    padding-left: calc(1.5 * var(--base-spacing));
    padding-bottom: var(--base-spacing);
  }
  .card-no-data,
  .no-data {
    position: absolute;
    padding: 0;
    left: 0;
    right: auto;
    width: 100% !important;
  }
  &.page-performance-detail {
    .react-grid-layout {
      width: calc(100% - 15px) !important;
    }
    &.page-performance-content-insight {
      .card-header {
        padding-left: calc(1.5 * var(--base-spacing)) !important;
        //margin-top: calc(.5 * var(--base-spacing));
        @include media-breakpoint-down(md) {
          padding-left: var(--base-spacing) !important;
        }
      }
      .table {
        margin-top: var(--base-spacing);
        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }
      .table-search-box {
        padding-right: 0;
        padding-left: calc(1.25 * var(--base-spacing));
        @include media-breakpoint-down(md) {
          padding-right: calc(1.25 * var(--base-spacing));
        }
      }
      .search-box-container-box {
        @include media-breakpoint-down(md) {
          width: 100%;
          float: left !important;
        }
      }
      .search-box-container {
        bottom: calc(-3.125 * var(--base-spacing));
        @include media-breakpoint-down(md) {
          position: relative;
          bottom: 0;
          top: 1rem;
          left: 0;
          right: auto;
          float: left !important;
          margin-bottom: calc(2 * var(--base-spacing));
          width: 100%;
        }
      }
      .search-filter-box {
        display: none;
      }
      .card-no-data {
        top: 55%;
      }
    }
    .card-merices-container {
      .card-merices {
        padding-left: calc(1.5 * var(--base-spacing));
      }
    }
    .search-filter-box {
      margin-top: calc(-2.75 * var(--base-spacing));
      margin-bottom: calc(1.75 * var(--base-spacing)) !important;
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
      .table-search-box {
        padding-left: 0;
        margin-right: calc(-3.5 * var(--base-spacing));
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }
      .download-excel {
        margin-top: 0.625rem !important;
        .icon-nav-box {
          margin-left: 0;
        }
      }
    }
    .card {
      background: none;
      min-height: 20rem;
      &.pageviewsandusers-table {
        min-height: 19rem;
      }
      .no-data {
        top: 55%;
      }
    }
  }
  &.Notification-container {
    .card {
      min-height: 21rem;
      .no-data,
      .card-no-data {
        top: 55% !important;
      }
      .preloader {
        top: 55% !important;
      }
    }
  }
  &.webcast-manage-users {
    .card {
      min-height: 20rem;
      .no-data,
      .preloader {
        top: 55% !important;
      }
    }
  }
}
.tools-dashboard-card-loader {
  .date-range-container {
    .form-control {
      &.input-search {
        padding-right: calc(1.75 * var(--base-spacing)) !important;
      }
    }
  }
  .card {
    .card-header {
      @include media-breakpoint-down(md) {
        padding-bottom: calc(1.5 * var(--base-spacing));
      }
    }
    min-height: 20rem;
    .card-no-data,
    .no-data {
      top: 50%;
    }
    .preloader {
      z-index: 99 !important;
    }
  }
  &.card-spacing-bottom {
    margin-bottom: calc(1 * var(--base-spacing));
  }
  .visitor-search-download-box {
    margin-top: calc(0.25 * var(--base-spacing)) !important;
  }
  .icon-nav-list .icon-nav-box .btn-group .dropdown-toggle {
    margin-top: calc(0.1 * var(--base-spacing)) !important;
  }
  .card .dropdown .card-dropdown-btn {
    margin-right: calc(-0.25 * var(--base-spacing));
  }
  .search-box-container {
    .table-search-box {
      padding-right: calc(0.25 * var(--base-spacing)) !important;
      bottom: 0;
      top: 0;
    }
    @include media-breakpoint-down(md) {
      position: relative;
      bottom: 0;
      right: 0;
      top: -15px;
      width: 100%;
      padding-right: 1rem;
      margin-bottom: 1rem;
    }
  }
  .tab {
    &.main-tab {
      margin-left: calc(1 * var(--base-spacing)) !important;
      margin-right: calc(1 * var(--base-spacing)) !important;
      @include media-breakpoint-down(md) {
        margin-bottom: 0 !important;
        .nav-item {
          margin-right: 0 !important;
        }
      }
    }
    &.subtab {
      &.mt-4 {
        @include media-breakpoint-down(md) {
          margin-top: 0 !important;
        }
      }
    }
  }
  .tab-content {
    position: relative;
    min-height: 10rem;
    @include media-breakpoint-down(md) {
      margin-top: 0 !important;
    }
  }
  .table thead {
    .sorting-label {
      &.header-summary-label {
        min-height: auto;
      }
    }
  }
  .date-range-container {
    &.filter-container {
      @include media-breakpoint-down(md) {
        left: 1rem !important;
        width: calc(100% - 2rem) !important;
        top: calc(2 * var(--base-spacing));
      }
    }
  }
  .date-range-container {
    .dropdown-content {
      div {
        div {
          div {
            vertical-align: middle;
            display: inline-block;
            width: 100%;
          }
        }
      }
      input {
        margin-top: calc(0.125 * var(--base-spacing)) !important;
      }
      input,
      span {
        float: left;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: calc(0.8125 * var(--base-font-size)) !important;
      }
    }
  }
}
.tools-overview-key-insight-container,
.spc-equation-box,
.spc-minichart-equation-box,
.cas-minichart-equation-box,
.dividend-calculator-equation-box {
  &.card-spacing-bottom {
    margin-bottom: calc(1 * var(--base-spacing));
  }
}
.dashboard-card-loader,
.tools-dashboard-card-loader {
  .card {
    @include media-breakpoint-down(md) {
      min-height: 12rem;
    }
  }
}
.Notification-container,
.webcast-manage-users,
.alerts-table {
  .card {
    .preloader {
      top: 70% !important;
    }
  }
}
.box-height {
  display: flex;
  min-height: 21rem;
  justify-content: center;
  align-items: center;
}
.box-height {
  display: flex;
  min-height: 21rem;
  justify-content: center;
  align-items: center;
}
.tnf-insights-modal {
  .table {
    thead {
      th {
        .sorting-label {
          &.header-summary-label {
            @include media-breakpoint-up(md) {
              min-height: auto;
            }
          }
        }
      }
    }
  }
  .tab-content {
    position: relative;
    min-height: 15rem;
    @include media-breakpoint-down(md) {
      margin-top: 0 !important;
    }
    .trafficContent {
      .no-data {
        margin-top: calc(1 * var(--base-spacing));
      }
    }
  }
}
//service hour
.equation-calc-card-container {
  &.service-hour-equation {
    .card {
      width: 22%;
      min-height: calc(5.625 * var(--base-spacing));
      padding-top: calc(0.625 * var(--base-spacing));
      margin-bottom: calc(0.625 * var(--base-spacing));
    }
    .card-operator {
      width: 4%;
      line-height: calc(5.625 * var(--base-spacing));
    }
    .card.eq-card-1 {
      margin-bottom: calc(0.9375 * var(--base-spacing));
    }
  }
}
.report-history-filter {
  .search-box {
    .sb-search {
      right: var(--base-spacing);
    }
  }
}
.service-report-history.table-data .mobile-only-label-data,
.service-team.table-data .mobile-only-label-data {
  width: 60%;
}
.service-report-history.table-data .label-col-value,
.service-hour .service-team.table-data .label-col-value {
  width: 60%;
  display: inline-block;
  margin-top: calc(0.3125 * var(--base-spacing));
  margin-bottom: calc(0.3125 * var(--base-spacing));
}
.service-hour {
  .remaining-hr-msg {
    width: 100%;
    margin-top: calc(0.625 * var(--base-spacing));
    margin-bottom: calc(0.625 * var(--base-spacing));
  }
  .segment-content-panel {
    line-height: 2.25rem;
    font-size: var(--base-font-size);
  }
  .service-hour-summary {
    .card.eq-card-4 {
      margin-top: 0;
    }
    .card.eq-card-1,
    .card.eq-card-2,
    .card.eq-card-3,
    .card.eq-card-4 {
      width: 21%;
      margin-bottom: 0;
    }
    .card-operator {
      width: 5%;
    }
    .card {
      min-height: calc(5.625 * var(--base-spacing));
    }
    .card-key-insights {
      .key-insight-value {
        font-size: 3rem;
      }
      .card-value-title {
        font-size: 0.95rem;
      }
    }
  }
}
.visitor-key-insights-box {
  .accordion-body {
    min-height: 9rem;
    position: relative;
    .card-no-data {
      padding: 0;
      top: 50%;
      position: absolute;
    }
    .preloader {
      z-index: 99 !important;
    }
  }
  .card-body {
    padding-bottom: 0;
  }
}
.card-visitor-insights-container,
.equation-calc-card-container {
  .card {
    width: 17%;
    min-height: 7.25rem;
    float: left;
    margin: 0;
    box-shadow: none;
    position: relative;
    border: none;
    @include media-breakpoint-down(md) {
      min-height: 6.5rem;
      width: 42%;
      &.eq-card-1 {
        width: 92%;
      }
    }
  }
}
.tools-overview-key-insight-container {
  .equation-calc-card-container {
    .card {
      min-height: 5.25rem;
    }
  }
}
.tools-overview-subtab {
  .nav-item {
    @include media-breakpoint-down(md) {
      margin-top: calc(1.25 * var(--base-spacing));
    }
  }
  .tab-content {
    @include media-breakpoint-down(md) {
      position: relative;
      min-height: 3rem;
    }
  }
}
.card-visitor-insights-container {
  .card {
    @include media-breakpoint-down(md) {
      width: 42%;
      &.eq-card-1 {
        width: 92%;
      }
    }
  }
  .card-body {
    padding-bottom: 0;
  }
}
.key-insight-title-box {
  .btn-help {
    padding: 0 !important;
    margin-left: calc(0.5 * var(--base-spacing));
  }
}
.service-hour {
  .card-visitor-insights-container {
    .card {
      width: 21%;
      @include media-breakpoint-down(md) {
        width: 42%;
        &.eq-card-1 {
          width: 92%;
        }
      }
    }
  }
}
.card-visitor-insights-container .card-body,
.equation-calc-card-container {
  .card {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.card-visitor-insights-container .card-key-insights .card-value-title,
.equation-calc-card-container .card-key-insights .card-value-title {
  font-size: calc(0.75 * var(--base-font-size));
  font-weight: var(--font-weight-semi-bold);
  text-align: center;
  text-transform: uppercase;
}
.card-visitor-insights-container .card-key-insights .key-insight-value,
.equation-calc-card-container .card-key-insights .key-insight-value {
  margin-bottom: 0;
  font-size: 2.25rem;
  position: relative;
  @include media-breakpoint-down(md) {
    font-size: calc(1.5 * var(--base-font-size));
  }
  text-align: center;
  font-weight: 600;
}
.card-visitor-insights-container .card-key-insights .card-value-title,
.equation-calc-card-container .card-key-insights .card-value-title {
  .fa {
    margin-left: calc(0.1875 * var(--base-spacing));
  }
}
.card-visitor-insights-container,
.equation-calc-card-container {
  .card-operator {
    width: 3.75%;
    float: left;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    line-height: 3.5rem;
    @include media-breakpoint-down(md) {
      width: 8%;
    }
  }
}
.equation-calc-card-container {
  .card-operator {
    width: 5%;
    line-height: 6.25rem;
  }
}
.card-visitor-insights-container {
  .card {
    .insight-percentage-value {
      //position: absolute;
      // bottom: 0;
      // right: 0;
      // padding: 2px 2px 0px 4px;
      // border-radius: $border-radius 0 0 0;
      // border: $border-width solid $border-color;
      // border-bottom: none;
      // border-right: none;
      margin-left: calc(0.5 * var(--base-spacing));
      font-size: 0.75rem;
      line-height: normal;
      color: $text-color;
      background: none;
    }
  }
}
.service-hour {
  .primary-widget-box {
    padding: calc(1.125 * var(--base-spacing));
    padding-bottom: calc(0.8125 * var(--base-spacing));
    background: $layout-bg;
  }
}
.collapsible-box {
  .panel {
    border-radius: 0;
  }
}
.collapsible-box {
  .panel-default>.panel-heading {
    font-size: var(--base-font-size);
  }
}
.collapsible-box .panel-title {
  font-size: 1.6rem;
  line-height: normal;
}
.service-filter {
  .form-control {
    width: 20rem;
    float: right;
  }
  .slider-dropdown-tigger {
    float: right;
  }
}
.manage-subscription-table-data {
  .download-btn,
  .search-filter-box {
    position: absolute;
    right: 2.7rem;
    top: 6.7rem;
  }
  .search-filter-box {
    right: 0;
    @include media-breakpoint-down(md) {
      top: 9rem;
    }
  }
  .search-box-container {
    bottom: calc(-2.75 * var(--base-spacing));
    @include media-breakpoint-down(md) {
      position: relative;
      left: 0;
      top: calc(0.5 * var(--base-spacing)) !important;
      margin-bottom: calc(1.5 * var(--base-spacing)) !important;
      bottom: auto;
    }
  }
}
.email-description-box {
  max-width: 100%;
  max-height: 400px;
  overflow: auto;
  @include media-breakpoint-down(md) {
    max-height: 400px;
  }
}
.alerts-modal-chart-box,
.editable-pages {
  min-height: 10rem;
  .no-data {
    position: relative;
    top: 35%;
  }
}
.editable-pages {
  .preloader {
    top: calc(2 * var(--base-spacing)) !important;
  }
}
.alert-subscriber-popover,
.notification-url-popover {
  z-index: 99999 !important;
  @include media-breakpoint-down(md) {
    z-index: 9999999 !important;
    max-width: 15rem !important;
  }
  margin-right: 10px;
  li {
    font-size: calc(0.75 * var(--base-font-size));
  }
}
// common
body {
  font-size: var(--body-font-size);
  background-color: $bg-color !important;
}
.body-text {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
}
.text-link {
  color: $text-color !important;
}
.is-disabled {
  opacity: 0.5;
  cursor: auto;
  color: $primary !important;
  pointer-events: none;
  &:focus {
    box-shadow: none;
  }
}
.action-not-allowed {
  cursor: not-allowed !important;
}
.full-width {
  width: 100%;
}
.border-light {
  border-color: $id-grey-1 !important;
}
.form-select {
  box-shadow: none;
  border-color: $id-grey-1;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}
h2 , .h2{color: $id-title-color;}
.pod {
  display: inline-block;
  width: 100%;
}
.custom-dropdown-meu-box {
  padding: 0.35rem 1rem 1rem;
  background: $layout-bg;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  color: $text-color;
}
.alert-msg-danger,
.mandatory {
  font-size: calc(0.875 * var(--base-font-size));
  color: $danger !important;
}
.word-break {
  word-break: break-all;
  //display: inline-block;
}
p {
  font-size: var(--body-font-size);
}
.card-custom-title {
  font-family: var(--bs-font-sans-serif);
  font-size: calc(1.125 * var(--base-font-size));
  font-weight: var(--font-weight-semi-bold);
}
.no-data,
.card-no-data,
.no-data-matrices {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0.85rem 1.5rem;
  font-size: calc(1 * var(--base-font-size));
  font-weight: var(--font-weight-semi-bold);
  text-align: center;
  color: rgba($id-grey-3, 0.85);
  margin-top: calc(-0.75 * var(--base-spacing));
}
// .no-data,
// .card-no-data {
//     @include media-breakpoint-down(md) {
//         position: relative !important;
//         top: 0;
//     }
// }
// .card{
//     .card-no-data{
//         width: calc(100% - -0.5rem) !important;
//     }
// }
.link-icn-btn {
  background: none;
  border: none;
  &.text-light {
    color: $grey-text-color !important;
    svg {
      color: $icon-color !important;
    }
  }
}
.loader-center-align {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  width: auto;
  margin-top: -1.3125rem;
  margin-left: -1.3125rem;
  flex: none;
  height: auto;
}
.form-switch {
  .form-check-input {
    &:focus {
      box-shadow: none;
    }
  }
  &.switch-uncheck {
    .form-check-input {
      &:focus {
        border-color: $id-grey-2;
        outline: 0;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2393a5be'/%3E%3C/svg%3E");
      }
    }
  }
}
.form-filter-container {
  .filter-container {
    z-index: 9;
    margin-right: 0 !important;
    right: 1rem !important;
    @include media-breakpoint-down(md) {
      width: calc(100% - 2rem) !important;
      right: 2rem !important;
      top: 4rem !important;
    }
  }
}
.alerts-table {
  .form-filter-container {
    .filter-container {
      @include media-breakpoint-down(md) {
        top: 5.25rem !important;
      }
    }
  }
  .card-body {
    min-height: 15rem !important;
  }
  .preloader {
    top: 65% !important;
  }
  .no-data {
    @include media-breakpoint-down(md) {
      top: 2rem !important;
    }
  }
}
.archivedalert-table {
  .preloader {
    top: 65% !important;
  }
}
.managesubscription-table,
.alertsummery-table,
.reporthistory-table {
  .card-body {
    min-height: 20rem !important;
  }
  .preloader {
    top: 50% !important;
  }
  .no-data {
    @include media-breakpoint-down(md) {
      top: 2rem !important;
    }
  }
}
.unsupported-device-msg {
  color: $grey-text-color !important;
}
//to disable auto zoom in ios
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  input,
  select,
  textarea,
  .dropdown-toggle {
    &:focus {
      font-size: var(--base-font-size);
    }
  }
}
@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  input,
  select,
  textarea,
  .dropdown-toggle {
    &:focus {
      font-size: var(--body-font-size);
    }
  }
}
//utility
.no-bg {
  background: none !important;
}
.no-outline {
  outline: none !important;
}
@include media-breakpoint-down(md) {
  .md-ps-1 {
    padding-left: calc(1 * var(--base-spacing)) !important;
  }
  .md-pe-1 {
    padding-right: calc(1 * var(--base-spacing)) !important;
  }
}
//font weight
.card-subtitle {
  font-family: var(--bs-font-sans-serif);
  font-size: calc(0.875 * var(--base-font-size));
  font-weight: var(--font-weight-semi-bold);
}
.fw-semi-bold {
  font-weight: var(--font-weight-semi-bold);
}
.alertList-container {
  border: 1px solid rgba(82, 106, 178, 0.4);
  padding: 10px;
  border-radius: 10px;
  min-height: 170px !important;
  max-height: 170px;
  overflow-y: auto;
  .form-check-label {
    line-height: 1.45rem;
  }
}
.recipient-textarea {
  border: 1px solid rgba(82, 106, 178, 0.4);
  min-height: 170px !important;
  max-height: 170px;
  overflow-y: auto;
}
textarea {
  resize: none;
}
.info-text-preview {
  font: 0.82em Arial, Helvetica, sans-serif;
  color: #5b5b5b;
}
.status-mark {
  width: 21px;
  height: 21px;
  display: inline-block;
  border-radius: 50%;
}
.status-mark.positive {
  background: #088901;
}
.status-mark.negative {
  background: #b10202;
}
.positive {
  color: #088901 !important;
}
.model-table {
  height: 300px !important;
}
.opacity-5 {
  opacity: 0.5 !important;
}
.page-analytics-inner,
.visitor-analytics-inner {
  @include media-breakpoint-down(md) {
    .search-box-container {
      right: auto;
      bottom: auto;
      position: relative;
      margin-bottom: 1.5rem;
    }
    .tab {
      &.subtab {
        .nav-item {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.visitor-analytics-inner {
  @include media-breakpoint-down(md) {
    .search-box-container {
      .table-search-box {
        padding-right: var(--base-spacing) !important;
      }
    }
  }
}
.visitor-search-download-box,
.page-analytics-search-download-box {
  margin-top: calc(1.5 * var(--base-spacing)) !important;
  @include media-breakpoint-down(md) {
    margin-top: calc(0.5 * var(--base-spacing)) !important;
  }
}
.visitor-search-download-box {
  @include media-breakpoint-down(md) {
    margin-top: calc(-0.75 * var(--base-spacing)) !important;
  }
  .filter-container,
  .date-range-container {
    @include media-breakpoint-down(md) {
      width: calc(100% - 1.75rem) !important;
      top: 2rem;
    }
  }
  .date-range-container {
    .dropdown-content {
      div {
        div {
          div {
            vertical-align: middle;
            display: inline-block;
            width: 100%;
          }
        }
      }
      input {
        margin-top: calc(0.125 * var(--base-spacing)) !important;
      }
      input,
      span {
        float: left;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: calc(0.8125 * var(--base-font-size)) !important;
      }
    }
  }
}
//mobile specific
.hide-desktop {
  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}
.hide-ipad {
  @include media-breakpoint-down(xl) {
    display: none !important;
  }
}
@include media-breakpoint-down(md) {
  .hide-mobile,
  .card-metrices-container form {
    display: none !important;
  }
  .mt-6-mobile {
    margin-top: calc(1.5 * var(--base-spacing)) !important;
  }
}
.br-dddbdb {
  border-right: 1px solid #dddbdb;
}
.last-update {
  float: right;
  margin-top: 1.65rem;
  margin-right: 1rem;
  @include media-breakpoint-down(md) {
    margin-top: calc(-1.5 * var(--base-spacing));
    margin-bottom: calc(1 * var(--base-spacing));
  }
}
//table column witdth
.contentinsights-table {
  .table {
    th {
      &:first-child {
        width: 40%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 15%;
      }
    }
  }
  &.card {
    .card-body {
      min-height: 18rem !important;
    }
  }
}
.visitorinsights-table {
  .table {
    th {
      &:first-child,
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 14%;
      }
      &:nth-child(5) {
        width: 12%;
      }
    }
  }
}
.watchlist-table {
  .table {
    th {
      &:first-child,
      &:nth-child(2) {
        width: 22%;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: 14%;
      }
    }
  }
}
.visitorsContent,
.landing-page-content,
.exit-page-content {
  .table {
    th {
      &:first-child,
      &:nth-child(2) {
        width: 40%;
      }
      &:nth-child(3) {
        width: 20%;
      }
    }
  }
}
.pages-table {
  .table {
    th {
      &:first-child {
        width: 50%;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3),
      &:nth-child(4) {
        width: 15%;
      }
    }
  }
}
.landingpage-table,
.exitpage-table {
  .table {
    th {
      &:first-child {
        width: 70%;
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: 15%;
      }
    }
  }
}
.notifications-table,
.notification-table {
  .table {
    th {
      &:first-child {
        width: 40%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        width: 12%;
      }
    }
  }
}
.modal-body {
  &.notification-table {
    min-height: 17rem;
    .table {
      th {
        &:first-child {
          width: 15%;
        }
        &:nth-child(2) {
          width: 39%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          width: 12%;
        }
      }
    }
  }
}
.alerts-table {
  .table {
    th,
    td {
      padding-left: calc(0.5 * var(--base-spacing)) !important;
      padding-right: calc(0.5 * var(--base-spacing)) !important;
    }
    th {
      &:first-child {
        width: 18%;
        padding-left: calc(1.5 * var(--base-spacing)) !important;
        @include media-breakpoint-down(md) {
          padding-left: calc(1 * var(--base-spacing)) !important;
        }
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(7) {
        width: 12%;
      }
      &:nth-child(5) {
        width: 12%;
      }
      &:nth-child(8) {
        width: 10%;
        padding-right: calc(1.5 * var(--base-spacing)) !important;
      }
    }
  }
}
.manualalert-table {
  .table {
    th {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5) {
        width: 18%;
      }
      &:nth-child(4) {
        width: 28%;
      }
    }
  }
}
.archivedalert-table {
  .table {
    th {
      &:first-child,
      &:nth-child(2) {
        width: 21%;
      }
      &:nth-child(3) {
        width: 18%;
      }
      &:nth-child(4),
      &:nth-child(5) {
        width: 15%;
      }
      &:nth-child(6) {
        width: 10%;
      }
    }
  }
}
.manage-subscription-table-data {
  .table {
    th {
      &:first-child {
        width: 35%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        width: 15%;
      }
    }
  }
}
.alertsummery-table {
  margin-bottom: calc(0.75 * var(--base-spacing));
  .table {
    th {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 20%;
      }
    }
  }
}
.eventcalender-table {
  .table {
    th {
      &:first-child,
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6) {
        width: 12%;
      }
      &:nth-child(3),
      &:nth-child(5) {
        width: 26%;
      }
    }
  }
}
.webcast-table {
  .webcast-viewers-data {
    .table {
      th {
        &:first-child,
        &:nth-child(2) {
          width: 17%;
        }
        &:nth-child(4),
        &:nth-child(5) {
          width: 12%;
        }
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(7) {
          width: 14%;
        }
      }
    }
  }
  .question {
    .table {
      th {
        &:first-child,
        &:nth-child(2) {
          width: 30%;
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 20%;
        }
      }
    }
  }
}
.user-table {
  .table {
    th {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 16%;
      }
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: 13%;
      }
    }
  }
}
.documents-table {
  .table {
    th {
      &:first-child {
        width: 5%;
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 20%;
      }
      &:nth-child(5) {
        width: 15%;
      }
    }
  }
}
.reporthistory-table {
  .table {
    th {
      &:first-child {
        width: 30%;
      }
      &:nth-child(2),
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 20%;
      }
    }
  }
}
.search-table,
.trafficContent {
  .table {
    th {
      &:first-child {
        width: 65%;
      }
      &:last-child {
        width: 35%;
      }
    }
  }
}
.webcastpane {
  .table {
    th {
      &:first-child {
        width: 85%;
      }
      &:last-child {
        width: 15%;
      }
    }
  }
}
.apppane {
  .table {
    th {
      &:first-child {
        width: 75%;
      }
      &:last-child {
        width: 25%;
      }
    }
  }
}
.websitepane {
  .table {
    th {
      &:first-child {
        width: 55%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 15%;
      }
    }
  }
}
.iranalytics-pane {
  .card {
    min-height: 20rem;
  }
  .table {
    th {
      &:first-child {
        width: 40%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 15%;
      }
    }
  }
}
.service-centre-report-detail-modal {
  .table {
    th {
      &:first-child,
      &:nth-child(2),
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 55%;
      }
    }
  }
}
.alert-modal-filter-nav {
  @include media-breakpoint-down(md) {
    margin-top: calc(0.5 * var(--base-spacing)) !important;
  }
}
.alert-modal-search-nav {
  @include media-breakpoint-down(md) {
    margin-bottom: calc(2 * var(--base-spacing)) !important;
  }
}
.devicesContent,
.countryContent,
.subscribersContent {
  .alerts-modal-chart-box,
  .modal-loader-container {
    position: relative;
    min-height: 8rem;
    width: 100%;
    .preloader {
      position: absolute;
      left: 50%;
      top: 50%;
      right: auto;
      margin-left: calc(-0.625 * var(--base-spacing));
    }
  }
  .modal-loader-container {
    .preloader {
      margin-left: calc(-1.25 * var(--base-spacing));
    }
  }
  .no-data {
    position: relative;
    top: 3rem;
    margin-bottom: 4rem;
  }
  .api-chart {
    display: inline-block;
    width: 100%;
  }
}
.subscribersContent {
  .table {
    .table-btn {
      @include media-breakpoint-down(md) {
        font-size: calc(0.75 * var(--body-font-size)) !important;
      }
    }
  }
}
.card {
  .preloader {
    z-index: 99 !important;
  }
}
//React grid layout
//Change css when react grid item order will be changed or rearranged
.rgl-website-analytics,
.rgl-alert-analytics,
.rgl-webcast-analytics,
.rgl-manual-alerts,
.rgl-archive-alerts,
.rgl-manage-subscriber,
.rgl-alert-summary,
.service-hour .react-grid-layout,
.websitepane .react-grid-layout,
.apppane .react-grid-layout,
#userAnalyticsContent,
#userAnalyticsContent .react-grid-layout,
#searchContent .react-grid-layout {
  height: auto !important;
  .react-grid-item {
    .card-body {
      min-height: 20rem;
      .preloader {
        top: 50% !important;
      }
    }
    &:last-child {
      height: auto !important;
    }
  }
}
.rgl-manual-alerts,
.rgl-archive-alerts,
.rgl-manage-subscriber,
.rgl-alert-summary {
  width: calc(100% - 12px) !important;
  .react-grid-item {
    .card {
      min-height: 15rem;
    }
    width: 100% !important;
  }
}
#userAnalyticsContent {
  .react-grid-layout {
    .react-grid-item {
      padding-bottom: calc(1.5 * var(--base-spacing));
    }
  }
}
.rgl-archive-alerts,
#searchContent {
  .react-grid-item {
    .card {
      min-height: 17rem;
    }
  }
}
.rgl-webcast-analytics {
  .react-grid-item {
    .card {
      min-height: 22rem;
      .preloader {
        top: 60% !important;
      }
    }
  }
}
.websitepane .react-grid-layout,
.apppane .react-grid-layout,
.webcastpane {
  .react-grid-item {
    transform: none !important;
  }
  .card {
    overflow: hidden;
  }
}
.rgl-alert-analytics {
  .filter-box-tigger {
    .icon-nav-box {
      margin-top: calc(-0.125 * var(--base-spacing)) !important;
    }
  }
}
#searchContent {
  .card-no-data {
    margin-top: 3rem !important;
  }
  .page-search-box {
    margin-top: calc(1.5 * var(--base-spacing));
    margin-right: calc(1 * var(--base-spacing));
  }
}
.my-account-container {
  min-height: 15rem;
  .preloader {
    top: 50% !important;
  }
}
.modal-dialog .tab-pane {
  .chart-data-renderer {
    min-height: 20rem;
    position: relative;
    .card-no-data {
      top: 50% !important;
    }
  }
}
.modal-dialog .tab-pane {
  .chart-data-renderer-allpages-popup-trafficsource {
    min-height: 20rem;
    position: relative;
    .card-no-data {
      top: 100% !important;
    }
  }
}
//pdf download
.pdf-download-title {
  font-size: calc(1.25 * var(--base-font-size));
  font-weight: 600;
}
.pdf-download-subtitle {
  font-size: calc(0.9375 * var(--base-font-size));
  font-weight: 500;
}
.pdf-download-btn {
  margin-top: calc(-0.1875 * var(--base-spacing)) !important;
  min-height: auto !important;
  &:focus,
  &:hover {
    background: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
  &+.dropdown-menu {
    left: auto !important;
    right: 0 !important;
    transform: none !important;
    top: 1.5rem !important;
    min-width: 7rem;
    z-index: 99;
    .dropdown-item-download {
      text-align: left !important;
    }
  }
}
.icon-nav-box {
  .dropdown[title="Download"] {
    .dropdown-menu {
      left: auto !important;
      right: 0 !important;
      transform: none !important;
      top: 1.5rem !important;
      min-width: 7rem;
      .dropdown-item-download {
        text-align: left !important;
      }
    }
  }
}
.webcast-questions-card-nav {
  .dropdown-menu {
    .dropdown-item-download {
      text-align: left !important;
    }
  }
  .webcast-table-nav {
    .icon-nav-box {
      .dropdown[title="Download"] {
        .dropdown-menu {
          z-index: 99;
        }
      }
    }
  }
}
.manage-user-additional-info {
  margin-top: calc(4.25 * var(--base-spacing)) !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
  .tooltip-inner {
    padding: 0.5rem;
    font-size: $tooltip-fontsize;
    border-radius: 0;
    background-color: $id-white;
    color: $text-color;
    border: 1px solid $id-grey-4;
    box-shadow: none;
  }
  &[role="tooltip"] {
    padding: 0;
    border: none;
    border-radius: 0;
    .tooltip-inner {
      text-align: left;
    }
    .bs-tooltip-top {
      .tooltip-arrow {
        bottom: -1rem;
      }
    }
  }
}
.manage-user-additional-info-label {
  font-size: calc(0.75 * var(--base-font-size));
  &.btn-help {
    @include media-breakpoint-down(md) {
      display: block !important;
    }
  }
}
//Line chart and Multibar chart related styles.
.legend-holder,
.legend-holder-line {
  font-size: 12px;
  font-weight: bold;
  transform: translate(51px, 245px);
  @include media-breakpoint-down(md) {
    transform: translate(0, 245px);
  }
  width: 100%;
  text-align: center;
}
.legend-holder-line-big {
  font-size: 12px;
  font-weight: bold;
  transform: translate(310px, 245px);
  @include media-breakpoint-down(md) {
    transform: translate(0, 235px);
  }
}
.month-ticks {
  transform: translate(-6px, 195px);
  @include media-breakpoint-down(md) {
    transform: translate(0, 245px);
  }
}
.grid-lines {
  transform: translate(0px, 24px);
}
.bars {
  transform: translate(0px, 24px);
}
.legend-holder-custom .legend-custom:nth-child(3) {
  transform: translate(-340px, 35px);
  @include media-breakpoint-down(md) {
    transform: translate(-350px, 35px);
  }
}
.legend-holder-custom {
  transform: translate(-195px, -50px);
  @include media-breakpoint-down(md) {
    transform: translate(-132px, -50px);
  }
}
.legend-holder-custom-2 {
  transform: translate(-195px, -32px);
  @include media-breakpoint-down(md) {
    transform: translate(-132px, -32px);
  }
}
.legend-holder-line {
  transform: translate(85px, 230px);
  @include media-breakpoint-down(md) {
    transform: translate(0, 230px);
  }
}
.common-dashboard-msg-container,
.alert-analytics {
  .card-body {
    position: relative;
    min-height: 17rem;
    .card-no-data,
    .no-data {
      top: 37%;
      // position: absolute;
      text-align: center;
    }
  }
}
.alert-analytics {
  .card-body {
    min-height: 24rem;
    .card-no-data,
    .no-data {
      top: 40%;
    }
  }
  .alert-statistis-chart-container-1 {
    .card-no-data,
    .no-data {
      top: 55%;
    }
  }
}
.website-analytics {
  .card-body {
    min-height: 26rem;
    .card-no-data,
    .no-data {
      top: 50%;
    }
  }
}
.webcast-analytics {
  .card-body {
    min-height: 28rem;
    .card-no-data,
    .no-data {
      top: 50%;
    }
  }
}
#alertStatistics {
  .row {
    @include media-breakpoint-up(md) {
      .col-md-6 {
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6) {
          padding-right: calc(var(--bs-gutter-x) / 4);
        }
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(7) {
          padding-left: calc(var(--bs-gutter-x) / 4);
        }
      }
    }
  }
  .col-spacing-bottom {
    margin-bottom: calc(0.75 * var(--base-spacing));
  }
}
#websitestatistics {
  .row {
    @include media-breakpoint-up(md) {
      .col-md-6 {
        &:first-child,
        &:nth-child(3),
        &:nth-child(5) {
          padding-right: calc(var(--bs-gutter-x) / 4);
        }
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6) {
          padding-left: calc(var(--bs-gutter-x) / 4);
        }
      }
    }
  }
  .col-spacing-bottom {
    margin-bottom: calc(0.75 * var(--base-spacing));
  }
}
.half-width-card-container {
  .col-md-6 {
    &:first-child {
      padding-right: calc(var(--bs-gutter-x) / 4);
    }
    &:nth-child(2) {
      padding-left: calc(var(--bs-gutter-x) / 4);
    }
  }
}
#webcastStatistics {
  .row {
    @include media-breakpoint-up(md) {
      .col-md-6 {
        &:first-child,
        &:nth-child(3),
        &:nth-child(5) {
          padding-right: calc(var(--bs-gutter-x) / 4);
        }
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6) {
          padding-left: calc(var(--bs-gutter-x) / 4);
        }
      }
      .col-md-12 {
        &:last-child {
          padding-right: calc(var(--bs-gutter-x) / 4);
          padding-left: calc(var(--bs-gutter-x) / 4);
        }
      }
    }
  }
  .col-spacing-bottom {
    margin-bottom: calc(0.75 * var(--base-spacing));
  }
}
// This is for the trial only you can remove this!!
.min-height-container {
  min-height: 15rem;
  // display: flex;
  // align-items: stretch;
}
// .manage-events-title-list{
//     display: inline-block;
//     width: 100%;
//     margin-top: 10px;
//     ul{
//         margin-left: 0;padding-left: 0;list-style-type: square;
//         li{
//             margin-left: 30px;
//             margin-bottom: 2px;
//             float: left;
//             padding-left: 0;
//             font-size: calc(.8125 * var(--base-font-size));
//         }
//     }
// }
/* AI Asistant Tooltip container */
.ai-asist-tooltip {
  position: fixed;
  display: inline-block;
  cursor: pointer;
  right: calc(1.875 * var(--base-spacing));
  bottom: calc(1.875 * var(--base-spacing));
}
.ai-asist-trigger {
  width: 3.125rem;
  height: 3.125rem;
  display: inline-block;
  text-align: center;
  border: 1px solid $id-grey-4;
  background: $id-grey-2;
  padding: calc(0.625 * var(--base-spacing));
  padding-top: calc(0.65 * var(--base-spacing));
  border-radius: 100px;
  color: $id-grey-4;
  font-size: 1.25rem;
  img {
    width: 2.25rem;
    height: 2.25rem;
  }
}
/* Tooltip text */
.ai-asist-tooltip .tooltiptext {
  visibility: hidden;
  width: 11.25rem;
  background-color: $id-grey-4;
  color: $id-white;
  text-align: center;
  border-radius: 5px;
  padding: calc(0.3125 * var(--base-spacing));
  position: absolute;
  z-index: 1;
  bottom: 0.5rem;
  right: 4.25rem;
  margin-left: 0;
  opacity: 0;
  transition: opacity 0.3s;
}
/* Tooltip arrow */
.ai-asist-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 35%;
  right: calc(-0.5625 * var(--base-spacing));
  margin-left: 0;
  border-width: 5px;
  border-style: solid;
  border-color: $id-grey-4 transparent transparent transparent;
  transform: rotate(-90deg);
}
/* Show the tooltip text when hovering */
.ai-asist-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.modal-title {
  a {
    color: $id-grey-3;
    font-size: calc(1 * var(--base-font-size));
  }
}
.ai-asistant-landing,
.ai-asistant-detail {
  .modal-title {
    .ai-modal-subtitle {
      font-size: calc(0.875 * var(--base-font-size));
      display: inline-block;
      margin-left: calc(0.3125 * var(--base-spacing));
    }
  }
}
.ai-asistant-landing {
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    padding: calc(0.625 * var(--base-spacing));
    th,
    td {
      padding: 12px 20px;
      text-align: left;
      border-bottom: 1px solid $id-grey-2;
    }
    th {
      font-weight: bold;
    }
    td span {
      display: block;
    }
    td .table-title-text {
      font-size: calc(1.125 * var(--base-font-size));
      margin-bottom: calc(0.625 * var(--base-spacing));
    }
  }
  img {
    width: 100%;
    max-width: 25px;
  }
  h2 {
    span {
      font-size: calc(0.875 * var(--base-font-size));
      display: inline-block;
      margin-left: 5px;
    }
    a img {
      transform: rotate(180deg);
      position: relative;
      top: 4px;
      margin-right: calc(1.25 * var(--base-spacing));
    }
    span {
      font-size: calc(0.875 * var(--base-font-size));
      display: inline-block;
      margin-left: 5px;
      font-weight: normal;
    }
    span i {
      display: inline-block;
      font-style: normal;
      margin: 0 10px;
    }
    a img {
      transform: rotate(180deg);
      position: relative;
      top: 4px;
      margin-right: calc(1.25 * var(--base-spacing));
      width: 100%;
      max-width: 25px;
    }
  }
}
.ai-asistant-detail {
  .page-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(2.5 * var(--base-spacing));
  }
  .title-text,
  td .table-title-text {
    font-size: 0.875rem;
    margin-bottom: calc(0.3125 * var(--base-spacing));
    font-weight: 600;
  }
  .page-heading .original-button a {
    display: inline-block;
    border: 1px solid $id-blue;
    background: transparent;
    color: $id-blue;
    text-decoration: none;
    padding: 10px 20px;
    transition: all 0.3s ease;
  }
  .page-heading .original-button a img {
    display: inline-block;
    max-width: 11px;
    margin-left: 10px;
  }
  .container {
    clear: both;
  }
  .left-content {
    float: left;
    width: 65%;
  }
  .left-container-details ul {
    margin-left: calc(2 * var(--base-spacing));
    padding-left: 0;
    li {
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
    }
  }
  .left-container-details li ul {
    display: inline-block;
    margin-left: calc(0.625 * var(--base-spacing));
    list-style: none;
  }
  .right-content {
    float: left;
    width: 35%;
  }
  table {
    margin-top: calc(0.625 * var(--base-spacing));
    border-top: 1px solid $ui-lines;
    border-bottom: 1px solid $ui-lines;
  }
  table .emoji {
    padding: 0 10px;
  }
}
.AI-Loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.paragraph {
  font-size: large;
}
.btn-input-field {
  display: flex;
  justify-content: space-between;
}
.recipient-textarea-DS {
  border: 1px solid rgba(82, 106, 178, 0.4);
  min-height: 100px !important;
  max-height: 100px;
  overflow-y: auto;
}
.custom-disable {
  opacity: 0.65;
  pointer-events: none;
}
.package {
  &--Essential {
    background-color: $package-essential-color;
  }
  &--Web {
    background-color: $package-web-color;
  }
  &--Standard {
    background-color: $package-standard-color;
  }
  &--EssentialPlus-WP {
    background-color: $package-essentialPlusWP-color;
  }
  &--Premium {
    background-color: $package-premium-color;
  }
  &--EssentialPlus {
    background-color: $package-essentialPlus-color;
  }
  &--Elite {
    background-color: $package-elite-color;
  }
}
.avatar {
  position: relative;
  display: inline-block;
  &--container {
    display: flex;
    align-items: center;
    gap: 10px;
    .name-package {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 10px;
    }
    &__name {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      margin-left: 5px;
    }
    &__circle {
      cursor: pointer;
      background-color: $id-blue;
      padding: 8px;
      margin-left: 5px;
      border-radius: 25%;
      color: #ffffff;
      font-weight: 900;
      &:hover {
        background-color: $secondary;
      }
      &--image {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }
  }
  &--dropdown-menu {
    position: absolute;
    top: 45px;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    z-index: 1000;
    overflow: hidden;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  &--dropdown-menu.open {
    opacity: 1;
    transform: scaleY(1);
  }
  &--dropdown-menu.close {
    opacity: 0;
    transform: scaleY(0);
  }
  &--dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &--dropdown-menu li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    font-size: small;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: $id-bg-grey-c;
    }
    &:last-child {
      border-bottom: none;
    }
    .avatar-menu-icons {
      margin-right: 7px;
    }
    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      fill: currentColor;
    }
  }
}
.show-more-icon {
  margin-left: 7px;
}
/*feedback-popupbox Modal styles  Start*/
.modal-content-feedback {
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-header {
    padding: 0;
    padding-bottom: 1rem !important;
  }
  .modal-content-feedback {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    position: relative;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  /* Feedback Form styles */
  .feedback-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .form-group {
    display: flex;
    flex-direction: column;
  }
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  input[type="text"],
  textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
  }
  textarea {
    resize: none;
  }
  input[type="file"] {
    padding: 5px;
    font-size: 14px;
  }
  .submit-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .submit-button:hover {
    background-color: #0056b3;
  }
}
.file-validation-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #B42318;
}
/*feedback-popupbox Modal styles  End*/
.rdrDefinedRangesWrapper {
  display: none;
}