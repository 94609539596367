// =========================================================
// * Volt Pro React Dashboard
// =========================================================

// * Product Page: https://themesberg.com/product/dashboard/volt-pro-react
// * Copyright 2021 Themesberg (https://www.themesberg.com)
// * License Information (https://themesberg.com/licensing)

// * Designed and coded by https://themesberg.com

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. Please contact us to request a removal.


//@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
//@import "volt/variables";

// Custom Variables
@import "custom-variables";

// Variables for light theme
@import "volt/themes/variables-light";

// Variables for dark theme
//@import "volt/themes/variables-dark";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!
@import "custom";

// vendor styles
@import "@fortawesome/fontawesome-free/css/all.css";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import "react-datetime/css/react-datetime.css";